!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('app/helper/connectionstatus.se',[
      'lodash',
      'app.env',
      'app/config/constants',

      'jquery',
      'toasties',
      'app/helper/logging.se',

      'backboneMdl',

      'app/services/seEchoApiSrv'
    ], factory)
  }
}(function (
  _,
  ENV,
  CONST,
  _jq,
  Toasties,
  seLogging,
  BackboneBase,
  echoApiSrv
) {

  /**
   * @callback ConnectionStatusFactory
   * @param _ {lodash}
   * @param ENV {AppEnv}
   * @param CONST {AppConstants}
   * @param _jq {jQuery}
   * @param Toasties {toasties}
   * @param seLogging {seLogging}
   * @param BackboneBase {BackboneBase}
   * @param echoApiSrv {echoApiService}
   * @return {ConnectionStatusModelInstance}
   * @constructor
   */

  /**
   * @typedef ConnectionStatusModelInstance
   * @property {function():Boolean} isOnline
   * @property {function(intervalMs):Boolean} intervalPing intervalMs
   * @property {Boolean} isPinging
   * @property {function({interval: Number}):Boolean} setNextPing options
   * @property {function({interval?: number|boolean, forced?: Boolean, statusChanged?: Boolean, isNextPing?: Boolean}):Boolean} ping options
   * @property {function():Number} offlineForMs
   * @property {function(Function)} onReconnect
   * @property {function(Function)} onFail
   * @property {function()} stop stops an interval ping
   */

  var LOG_LABEL = 'CON STAT'

  /**
   * Connection status
   *
   * ## summary
   *
   * keeps a constant ping on the backends and triggers events on status change
   * uses
   *
   * ## methods
   *
   *
   * ## process
   *
   *
   * @returns {UpdateLogger}
   * @constructor
   */


  var
    $ = $ || _jq,

    CONNECTION_CHECK_INTERVAL_MS = ENV.ping_rate * 1000,
    MINIMUM_CHECK_INTERVAL_MS = 2000,

    CONNECTION_STATUS = {
      READY: CONST.STATUS.READY,
      LOST: CONST.STATUS.TIMEOUT,
      FAILED: CONST.STATUS.FAILED,
      TERMINATED: CONST.STATUS.DESTROYED,
    },

    LABELS = {
      TOASTY_CONNECTION_READY_UI: 'Connection restored ...',
      TOASTY_CONNECTION_LOST_UI: 'Connection lost ... retrying in ##RETRY_INTERVAL## ... <u>retry now</u>',

      TOASTY_CONNECTION_LOST_PLAY: {
        title: 'Slow server connection',
        text: 'A server response timed out. Please make sure you have ' +
          'a stable internet connection. We re-try in about ##RETRY_INTERVAL##.'
      },

      TOASTY_CONNECTION_FAILED: {
        title: 'Connection failed',
        text: 'Please make sure you have a stable internet connection, then reload this page'
      }
    },

    IS_PLAY = ENV.play_api_url !== undefined,       // TODO: remove IS_PLAY flag and introduce settings on create

    DEFAULT_AUTOSTART = false // was earlier true for non-play situations but now a ping interval must be started via .ping()

  function ToastyAddCountdown (conToasty, timeout_datetime) {
    var tmpConToasty = conToasty,
      interval0 = setInterval(function () {
        var timeLeftSeconds = Math.round((timeout_datetime - Date.now()) / 1000)
        if (!tmpConToasty
          || !tmpConToasty.isShown()
          || timeLeftSeconds <= 0) {
          clearInterval(interval0)
          return
        }

        tmpConToasty.find('span[data-timeout]').text(timeLeftSeconds + 's')

      }, 250)
  }

  /**
   * @type {function}
   * @returns ConnectionStatusModelInstance
   */
  var ConnectionStatusModel = BackboneBase.Model.extend({

    _CON: CONNECTION_STATUS,

    defaults: function () {
      return {
        latestSuccessfulConnection: Date.now(),
        autoping: DEFAULT_AUTOSTART,
        checkIntervalMs: CONNECTION_CHECK_INTERVAL_MS,

        isUnloading: false,

        nextPingTimestamp: 0,
        nrRetries: 0,

        // -- synced vals
        forcedRedirect: null
      }
    },

    _nextCheckInMs: function () {
      var _this = this,
        nextPingTimestamp = +_this.get('nextPingTimestamp'),
        nextCheckinMs = !isNaN(nextPingTimestamp) && (nextPingTimestamp - Date.now())

      return nextCheckinMs > 0 && nextCheckinMs || 0
    },

    init: function (params) {
      var _this = this

      _this.setStatus(CONNECTION_STATUS.READY)
      _this._setupStatusChangeEventHandling()

      if (IS_PLAY) {                            // TODO: remove IS_PLAY flag and introduce settings on app create -ab
        _this._setupToastiesPlay()

      } else {
        _this._setupToastiesUi()
        _this._registerNavigatorOnlineStatus() // not on PLAY because user needs no instant info on con loss
      }

      _this._setupBroadcastSync()

      var autoping = _this.get('autoping'),
        checkIntervalMs = +_this.get('checkIntervalMs') || false

      if (autoping) {
        _this.ping({
          interval: checkIntervalMs
        })
      }

      window.addEventListener('beforeunload', function () {
        _this.set('isUnloading', true);
      })

    },

    _setupBroadcastSync: function(){
      if(typeof BroadcastChannel === 'undefined'){
        return
      }

      const
        _this = this,
        bc = new BroadcastChannel("se_connectionstatus")

      bc.addEventListener('message', function(ev){
        const
          status = ev.data && ev.data.status,
          data = ev.data && ev.data.data

        // console.log(LOG_LABEL, 'bc message', ev)

        if(Object.values(CONNECTION_STATUS).includes(status) && _this.status !== status){
          // sync data first
          if(data) {
            _this.set(data)
          }
          // then trigger status change
          _this.setStatus(status)

        }
      })

      _this.on('status', function(mdl0, status){
        var data = null
        if(status === CONNECTION_STATUS.TERMINATED){
          data = _this.pick('forcedRedirect')
        }
        // console.log(LOG_LABEL, "bc post", { data: data, status: status })
        bc.postMessage({
          status: status,
          data: data
        })
      })
    },

    /**
     * returns true or false if window.navigator.onLine is supported -- otherwise NULL
     * @returns {any}   true, false, null (if not supported)
     */
    isOnline: function () {
      return window.navigator.onLine !== undefined ? window.navigator.onLine : null // if information not given assume we're online
    },

    _setupStatusChangeEventHandling: function () {
      var _this = this

      _this.on('status', function (mdl, newStatus, oldStatus) {
        if(_this.get('isUnloading')){
          return // ignore anything that happend after unloading
        }

        switch (newStatus) {
          case CONNECTION_STATUS.TERMINATED:
            var redirectUrl = _this.get('forcedRedirect')
            if(redirectUrl){
              window.location.href = redirectUrl
            }
            break;
          case CONNECTION_STATUS.READY:
            if (oldStatus !== newStatus) {
              _this.set('nrRetries', 0)
              _this.set('latestSuccessfulConnection', Date.now())

              _this.trigger(CONST.EVENTS.CONNECTION.READY)
              _this._onReconCb()
            }
            break
          case CONNECTION_STATUS.LOST:
            _this.trigger(CONST.EVENTS.CONNECTION.LOST)

            if (oldStatus !== newStatus) {
              _this.ping({ statusChanged: true })
            }

            break
          case CONNECTION_STATUS.FAILED:
            _this.trigger(CONST.EVENTS.CONNECTION.FAILED)
            _this._onFailCb()
            break
        }
      })
    },

    _setupToastiesUi: function () {
      var _this = this

      var conToasty,
        ConToasty = function (message, options) {
          if (conToasty) {
            if (conToasty.isShown())
              conToasty.clear()
          }

          options = _.merge({
            wide: true,
            type: 'info',
            timeOut: 3000
          }, options || {})

          conToasty = Toasties.tiny(message, '', false, options)
          conToasty.message = message

          return conToasty
        },
        noConToastyOptions = {
          type: 'warning',
          timeOut: 0,
          extendedTimeOut: 0,
          onclick: function () {
            if (conToasty && conToasty.clear)
              conToasty.clear()

            _this.ping({ forced: true })
          }
        }

      _this.on('ping', function () {
        if (conToasty && conToasty.clear) {
          conToasty.clear()
        }
      })

      function showConnectionLostToasty () {
        if (_this.status !== CONNECTION_STATUS.LOST || _this.get('isUnloading')) {
          return
        }

        var nextCheckinMs = +_this.get('nextPingTimestamp') - Date.now(),
          checkIntervalMs = nextCheckinMs,
          checkIntervalStr = Math.floor(nextCheckinMs / 1000) + 's ',
          toasty

        if (nextCheckinMs <= 0) return

        toasty = ConToasty(LABELS.TOASTY_CONNECTION_LOST_UI.replace(/##RETRY_INTERVAL##/, '<span data-timeout></span>'), noConToastyOptions)

        ToastyAddCountdown(toasty, +_this.get('nextPingTimestamp'))
      }

      _this.on('ping-upcoming', showConnectionLostToasty)

      _this.on('status', function (mdl, newStatus, oldStatus) {
        if(_this.get('isUnloading')) {
          return
        }

        switch (newStatus) {
          case CONNECTION_STATUS.READY:
            if (oldStatus !== CONNECTION_STATUS.READY)
              ConToasty(LABELS.TOASTY_CONNECTION_READY_UI)

            break
          case CONNECTION_STATUS.LOST:
            // toasty triggered on 'ping-upcoming'

            break
          case CONNECTION_STATUS.FAILED:

            seLogging.error_plain(LABELS.TOASTY_CONNECTION_FAILED.title, LABELS.TOASTY_CONNECTION_FAILED.text, 'Reload', function () {
              window.location.reload()
            }, { timeOut: 0, closeButton: false })

            break
        }
      })
    },

    stop: function(){
      var _this = this
      _this.set('autoping', false)
    },

    _setupToastiesPlay: function () {
      var _this = this,
        conToasty

      function showConnectionLostToasty () {
        if (_this.status !== CONNECTION_STATUS.LOST) return

        var nextCheckinMs = +_this.get('nextPingTimestamp') - Date.now(),
          checkIntervalMs = nextCheckinMs,
          checkIntervalStr = Math.round(nextCheckinMs / 1000) + ' seconds'

        if (nextCheckinMs <= 0) return

        conToasty = seLogging.warn(
          LABELS.TOASTY_CONNECTION_LOST_PLAY.title, LABELS.TOASTY_CONNECTION_LOST_PLAY.text.replace(/##RETRY_INTERVAL##/, '<span data-timeout></span>'),
          'Retry now',
          function () {
            _this.ping({ forced: true })
          }
        )

        ToastyAddCountdown(conToasty, +_this.get('nextPingTimestamp'))
      }

      _this.on('ping', function () {
        if (conToasty && conToasty.clear) {
          conToasty.clear()
        }
      })

      _this.on('ping-upcoming', showConnectionLostToasty)

      _this.on('status', function (mdl, newStatus, oldStatus) {
        if(_this.get('isUnloading'))
          return

        switch (newStatus) {
          case CONNECTION_STATUS.READY:
            if (oldStatus !== CONNECTION_STATUS.READY) {
              // dont show to user
            }

            break
          case CONNECTION_STATUS.LOST:
            // toasty triggered on 'change:ping-upcomingTimestamp'

            break
          case CONNECTION_STATUS.FAILED:
            seLogging.error_plain(LABELS.TOASTY_CONNECTION_FAILED.title, LABELS.TOASTY_CONNECTION_FAILED.text, 'Reload', function () {
              window.location.reload()
            }, { timeOut: 0, closeButton: true })

            break
        }
      })
    },

    _registerNavigatorOnlineStatus: function () {
      var _this = this,
        isOnline = _this.isOnline()

      if (isOnline === null) {
        console.warn(LOG_LABEL, 'browser does not support navigator.onLine')
        return
      }

      window.addEventListener('online', function () {
        if (_this.status === CONNECTION_STATUS.FAILED) return // dont recover from FAILED
        _this.ping() // verify connection to server
      })
      window.addEventListener('offline', function () {
        _this.setStatus(CONNECTION_STATUS.LOST)
      })

      if (_this.isOnline() === false) {
        _this.setStatus(CONNECTION_STATUS.LOST)
      }
    },

    intervalPing: function (intervalMs) {
      var _this = this

      intervalMs = intervalMs || _this.get('checkIntervalMs')

      _this.ping({
        interval: intervalMs
      })
    },

    isPinging: null,
    _nextPing: { timeout: null, interval: null, promise: null, premiseStatus: CONNECTION_STATUS.READY },
    setNextPing: function (options) {
      var _this = this

      if(!_this.get('autoping')){
        return
      }

      var interval = options && options.interval || _this._nextPing.interval,
        nextPingTimestamp,
        nextPingMs,
        retried

      switch (_this.status) {
        case CONNECTION_STATUS.READY:
          nextPingMs = interval

          break
        case CONNECTION_STATUS.LOST:
          retried = +_this.get('nrRetries')
          nextPingMs = ENV.async_timeout_seconds * 1000 * (1 + 0.5 * retried)

          break
        case CONNECTION_STATUS.FAILED:

          // NO FURTHER AUTO PINGS.

          break
      }

      clearTimeout(_this._nextPing.timeout)

      var _return = _this._nextPing.promise &&
        _this._nextPing.promise.state() === 'pending' &&
        _this._nextPing.promise
        || $.Deferred()

      if (nextPingMs) {
        nextPingMs = Math.max(nextPingMs, MINIMUM_CHECK_INTERVAL_MS)
        nextPingTimestamp = Date.now() + nextPingMs

        _this.set('nextPingTimestamp', nextPingTimestamp) // triggers next-retry-toasty
        _this._nextPing = {
          premiseStatus: _this.status + '',
          timeout: setTimeout(function () {
            _return.resolve(_this.ping({
              interval: interval,
              isNextPing: true
            }))
          }, nextPingMs),
          promise: _return,
          interval: interval
        }

        _this.trigger('ping-upcoming')
      } else {
        _return.resolve(_this.status)
      }

      return _return
    },

    /**
     * triggers a ping, and by default an interval
     * @param {object} [options]
     * @param {boolean|number} [options.interval=true] if false does only 1 ping
     * @param {boolean} [options.forced=false] force a ping NOW (otherwise it waits for the next min interval)
     * @param {boolean} [options.statusChanged=false] internal usage
     * @param {boolean} [options.isNextPing=false] internally used
     * @returns {any} void
     */
    ping: function (options) {
      var _this = this,
        _return = $.Deferred(),
        _minPingInterval = $.Deferred()

      options = _.merge({
        interval: true,
        forced: false,
        statusChanged: false,
        isNextPing: false
      }, options || {})

      if(options.interval){
        _this.set('autoping', true)
      }

      if(_this.get('isUnloading')) {
        return
      }

      if (_this.isPinging) {
        return _this.isPinging
      }

      if (options.statusChanged) {
        return _this.setNextPing(options)
      }

      if (!options.isNextPing &&
        !options.forced &&
        _this._nextPing.promise &&
        _this._nextPing.promise.state() === 'pending') {

        if (_this._nextPing.premiseStatus === _this.status) {
          return _this._nextPing.promise
        } else { // status changed meanwhile -> forcing a nextPing
          return _this.setNextPing(options)
        }
      }

      _this.isPinging = _return

      if (options.interval && isNaN(options.interval) || options.interval < MINIMUM_CHECK_INTERVAL_MS) {
        console.warn(LOG_LABEL, 'ping interval set to minimum', options, MINIMUM_CHECK_INTERVAL_MS)
        options.interval = MINIMUM_CHECK_INTERVAL_MS
      }

      setTimeout(function () {
        _minPingInterval.resolve()
      }, options.forced ? 0 : MINIMUM_CHECK_INTERVAL_MS)

      // dont recover from FAILED
      var
        onRedirect = function(redirectUrl){
          _this.set('forcedRedirect', redirectUrl)
          _this.setStatus(CONNECTION_STATUS.TERMINATED);
        },
        onSuccess = function () {
          switch (_this.status) {
            case CONNECTION_STATUS.TERMINATED:
            case CONNECTION_STATUS.FAILED:
              // dont recover from FAILED / TERMINATED
              break
            case CONNECTION_STATUS.LOST:
              _this.setStatus(CONNECTION_STATUS.READY)
              break
          }

          onComplete()
        },
        onTimeout = function () {
          if (_this.get('nrRetries') >= ENV.max_async_retries) {
            // -> FAILED.
            return onError()
          }

          _this.setStatus(CONNECTION_STATUS.LOST)
          onComplete()

        },
        onError = function () {
          _this.setStatus(CONNECTION_STATUS.FAILED)
          onComplete()
        },
        onComplete = function () {
          _return.resolve(_this.status)
          _this.isPinging = null

          _this.setNextPing(options)
        }

      _minPingInterval.then(function () {
        _this.trigger('ping')
        clearTimeout(_this._nextPing.timeout)

        if (_this.status === CONNECTION_STATUS.LOST) {
          var retries = +_this.get('nrRetries')
          _this.set('nrRetries', retries + 1)
        }

        _this._pingApi(onSuccess, onTimeout, onError, onRedirect)
      })

      return _return

    },

    /**
     * @param {XMLHttpRequest} xhr
     * @private
     */
    _getForcedRedirectHeader: function(xhr){
      var
        _this = this,
        redirectUrl = xhr && xhr.getResponseHeader('forced-redirect')

      return !!redirectUrl && redirectUrl
    },
    _pingApi: function (onSuccess, onConnectionLost, onConnectionError, onRedirect) {
      var _this = this

      var randnow = Date.now() + '-' + Math.random()

      echoApiSrv.echo(
        randnow,
        function succCb (data, status, xhr) {
          var redirectUrl = _this._getForcedRedirectHeader(xhr)

          if(redirectUrl){
            onRedirect(redirectUrl)
          }else if (data === randnow) {
            onSuccess()
          } else {
            onConnectionError()
          }

        },
        function errCb (errorMessage, statusCode, xhr, req) {

          var redirectUrl = _this._getForcedRedirectHeader(xhr)
          if (redirectUrl) {
            return onRedirect(redirectUrl)
          }

          switch (statusCode) {
            case 404:
            case 500:
              onConnectionError()
              break
            default:
              if (xhr.statusText === "timeout" ||
                xhr.getAllResponseHeaders() === "") {

                onConnectionLost()

                break
              } else {
                onConnectionError()
              }
              break
          }

          _this._getForcedRedirectHeader(xhr)

        }
      )
    },

    offlineForMs: function () {
      var _this = this
      return Date.now() - +_this.get('latestSuccessfulConnection')
    },

    _callQueue: function (queue) {
      var fnCb
      while (queue && queue.length && (fnCb = queue.shift())) {
        fnCb()
      }
    },
    _addToQueue: function (queue, fnCb) {
      if (typeof fnCb !== 'function')
        console.error(LOG_LABEL, 'bad parameter, expected function', fnCb)
      queue.push(fnCb)
    },

    _onrecon: [],
    _onReconCb: function () {
      this._callQueue(this._onrecon)
      this._onfail = []
    },
    /**
     * push function callback to the onReconnect queue or, if already connected, execute directly and trigger ping
     * on reconnect all queued up functions are called once and then discarded
     * both onReconnect and onFail queues are discarded once either of the events takes place
     * @param fnCb
     */
    onReconnect: function (fnCb) {
      var _this = this

      if (_this.status === CONNECTION_STATUS.READY) {
        fnCb()
        return
      }

      _this._addToQueue(_this._onrecon, fnCb)
      _this.ping()
    },

    _onfail: [],
    _onFailCb: function () {
      this._callQueue(this._onfail)
      this._onrecon = []
    },
    /**
     * push function callback to the onFail queue or, if already failed, execute directly
     * on fail all queued up functions are called once and then discarded
     * both onReconnect and onFail queues are discarded once either of the events takes place
     * @param fnCb
     */
    onFail: function (fnCb) {
      var _this = this

      if (_this.status === CONNECTION_STATUS.FAILED) {
        fnCb()
        return
      }

      _this._addToQueue(_this._onfail, fnCb)
    }

  })

  return new ConnectionStatusModel()

});
