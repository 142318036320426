define('app/helper/settings',[
  'app/config/constants'
], function (
  CONST
) {

  var
    Settings = function () {
      if (!localStorage || !localStorage.getItem) {
        console.warn('Local storage not available', 'You will not be able to auto-save your settings beyond this session')
      }
      if (!sessionStorage || !sessionStorage.getItem) {
        console.warn('Session storage not available', 'You will not be able to auto-save your settings')
        return
      }
      this.available = true

      function getStorage (type) {

        type = type || CONST.STORAGE_TYPE.LOCAL

        switch (type) {
          case CONST.STORAGE_TYPE.SESSION:
            return sessionStorage
            break
          case CONST.STORAGE_TYPE.LOCAL:
            return localStorage || sessionStorage
            break
          default:
            throw new Error('Unsupported storage type requested; ' + type)
            break
        }
      }

      function get (key, type) {
        var cvalstr = getStorage(type).getItem(key),
          dataStr,
          data
        try {
          if (cvalstr) {
            dataStr = atob(cvalstr).trim()
          }
          if (dataStr && dataStr.match(/^\{.*\}$/)) {
            data = JSON.parse(atob(cvalstr))
          }
        } catch (err) {
          console.warn('could not retrieve value due to decoding issues; resetting storage key;', key, type, err)
          set(key, null, type)
        }
        return data ? data.val : null
      }

      function set (key, val, type) {
        var cval = { val: val },
          strval = btoa(JSON.stringify(cval))
        if (strval.length > 1024 * 128 * 8) {
          console.warn('Warning! Dont save too big values into storage. Saving ' + Math.ceil(strval.length / 8 / 1024) + 'kB.')
        }
        getStorage(type).setItem(key, strval)
      }

      function setting (key, type) {
        return {
          get: function () { return get(key, type) },
          set: function (val) { return set(key, val, type) }
        }
      }

      this.get = get
      this.set = set

      this.session = function (key) {
        return setting(key, CONST.STORAGE_TYPE.SESSION)
      }
      this.local = function (key) {
        return setting(key, CONST.STORAGE_TYPE.LOCAL)
      }
    }

  return new Settings()

});
