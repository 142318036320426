!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('mitt',[
      'mitt'
    ], factory)
  }
}(function() {
  function n (n) {
    return n = n || Object.create(null), {
      on: function(e, t) {(n[e] || (n[e] = [])).push(t)},
      off: function(e, t) {n[e] && n[e].splice(n[e].indexOf(t) >>> 0, 1)},
      emit: function(e, t) {(n[e] || []).slice().map(function(n) {n(t)}), (n["*"] || []).slice().map(function(n) {n(e, t)})}
    }
  }

  return n
})
;
