define('rjs.init',[

    "toasties"

], function (

    Toasties

) {

    var PAGE_IS_UNLOADING;
    window.addEventListener("beforeunload", function(){
        PAGE_IS_UNLOADING = true;
    });

    var SE = window.SE;

    var reloadedStatus = SE.reloadedStatus = (function(){
        var _this = this,
            lsItemName = "_reloadedStatus",
            maxReloads = SE.MAX_ASYNC_RETRIES,
            autoResetTimeoutMs = SE.ASYNC_TIMEOUT_SECONDS * 1000 + 10 * 1000;

        var _fn = {
                get: function(){
                    if (!localStorage) return;
                    return parseInt(localStorage.getItem(lsItemName));
                },
                set: function (newVal) {
                    if (!localStorage) return;
                    localStorage.setItem(lsItemName, newVal);
                    return true;
                },
                reset: function(){
                    return _fn.set(0)
                }
            },
            fn = {
                status: function () {
                    return _fn.get();
                },
                reloadAllowed: function () {
                    var status = _fn.get();
                    return !isNaN(status) && status <= maxReloads;
                },
                saveReload: function () {
                    if(!fn.reloadAllowed()) return;
                    var status = _fn.get();
                    return _fn.set(status + 1);
                },
                reset: _fn.reset
            };

        // auto-reset after autoResetTimeoutMs
        setTimeout(function () {
            _fn.reset();
        }, autoResetTimeoutMs);

        return fn;
    }());


    var rjsFailed = false;

    requirejs.onError = function (err) {

        err.message = err.message + " on " + location.href;

        console.warn("REQUIREJS", err.requireType, err);

        switch(err.requireType){
            case "timeout":

                if(!rjsFailed){

                    if(reloadedStatus.reloadAllowed()){
                        Toasties.warning("Slow connection.", "Please standby while cache is updated.");

                        setTimeout(function(){
                            if(reloadedStatus.saveReload()){
                                window.location.reload();
                            }
                        }, 1500);

                    }else{

                        reloadedStatus.reset();

                        Toasties.error(
                            "Loading unsuccessfull.",
                            "Please check your internet connection and reload interface.",
                            "Reload page",
                            function(){
                                window.location.reload();
                            }
                        );
                    }

                    break;

                }

            case "scripterror":
                if(PAGE_IS_UNLOADING){
                    // in Firefox not-yet-loaded scripts are not properly handled on unload but
                    // cause an error - this should be ignored

                    return;
                }

            default:

                rjsFailed = true;

                throw err;

                //console.warn("REQUIREJS", "not-timeout", err);
                //
                //var errObj = _fn.utils.createError(err.shortMessage, err);
                //errObj.type = ERROR_TYPE.REQUIREJS + "." + err.name;
                //_fn.handleError(err);

                break;

        }
    };

});
