!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('app/services/seEchoApiSrv',[
      'app.env',
      'lodash',

      'app/helper/logging.se',

      'jquery'
    ], factory)
  }
}(function (
  ENV,
  _,
  seLogging,
  _jQ
) {

  var LOG_LABEL = 'ECHO API SRV'

  // IE9 FormData fix
  var FormData = window.FormData
  if (typeof (FormData) === 'undefined') {
    var FormData = null
  }

  var $ = window.$ || _jQ

  var PAGE_IS_UNLOADING
  window.addEventListener('beforeunload', function () {
    PAGE_IS_UNLOADING = true
  })

  var _v = {
      defaultRequestParams: {
        method: 'POST',
        headers: {
          //'X-Auth-Token': 'T1eyaTLvc79Q3SbmKBnFDztMW8GCEJ', // demo server TOKEN
          //'Content-Type': 'application/json',
          //'Accept': 		'application/json'
        },
        data: null, // will be overwritten
        timeout: ENV.async_timeout_seconds * 1000
      },
      customHeaders: {},
      apiModeAsync: true,
      windowIsUnloading: false
      //_tokenDb: 'seApi'
    },
    _fn = {
      utils: {
        /**
         * convert data object into uri parameter string, e.g. {a: "b c d", 2: 345} => a=b%20c%20d&2=345
         * @param data
         * @returns {string}
         */
        objToUrlParam: function (data) {
          var paramstr = ''

          for (var i in data) {
            if (paramstr.length > 0)
              paramstr += '&'

            paramstr += i + '=' + encodeURIComponent(data[i])
          }

          return paramstr

        },
        getUiSessionId: function () {

          var paramName = 'UISID'

          var cookies = document.cookie.split(';'),
            sessionUId

          _.forEach(cookies, function (cookie) {
            cookie = cookie.trim().split('=')
            if (cookie[0] === paramName) {
              sessionUId = cookie[1]
            }
          })

          if (!sessionUId) {
            console.warn(LOG_LABEL, 'Session Id missing', cookies)
            return
          }

          return sessionUId

        },
        createApiCallUri: function () {
          var url,
            IS_PLAY = ENV.play_api_url !== undefined

          // get parameters from current URI string, e.g. screen, survey_name, etc.
          var searchStr = location.search.replace(/^\??/, ''),
            params = searchStr.split('&').reduce(function (r, val) {
              var tmp = val.split('=')
              r[tmp[0]] = decodeURIComponent(tmp[1])
              return r
            }, {}),
            newSearchStr

          if (IS_PLAY) {
            url = ENV.play_api_url

            if (params.esp) {
              // this is a play final screen - dont echo here. session is closed.
              return false
            }

            params = {} // remove any parameters
            params.PLAYSID = ENV.play_sid

          } else {
            // get Ui session ID
            params.UISID = _fn.utils.getUiSessionId()

            // remove screen parameter
            delete (params.screen) // TODO: remove commenting on this line

            // add API parameter
            params.api = 'working_version'

            url = ENV.API_BASE_URL || (location.protocol + '//' + location.host + location.pathname)
          }

          newSearchStr = '?' + _.map(params, function (val, key) {
            return key + '=' + encodeURIComponent(val)
          }).join('&')

          return url + newSearchStr
        },
        xmlToJsml: function (dataXml) {
          var dataJsml

          if (dataXml) {
            dataJsml = JsonML.fromXML(dataXml, function (jml, xml) { // TODO: use this method also in default API XML->JSML conversion

              var filderedOutElementsCnt = 0,
                filtered = _.filter(jml, function (elm, i) {

                  // filter out line breaks "\n     " in XML that are interpreted as text elements
                  if (elm && elm.match && elm.match(/^\n\s*$/m) && elm.trim() == '') {
                    filderedOutElementsCnt++
                    return false
                  }

                  return true

                })

              return filtered

            })

            if (dataJsml && dataJsml.length && dataJsml[0] == '') {
              dataJsml = dataJsml[1]
            }

          }

          return dataJsml
        }
      },
      prepReq: function (uri, method, op, params, format, progressCb) {
        var req = _.clone(_v.defaultRequestParams, true),
          data = {}

        req.headers = _.clone(_.merge(req.headers, _v.customHeaders), true)
        _v.customHeaders = {}

        req.url = uri

        method = method ? method.toString().toUpperCase() : ''

        switch (method) {
          case 'POST':
          case 'DELETE':
          case 'PUT':
          case 'GET':
            // do nothing -- everything is already purrrrfect.
            break
          default :
            method = 'GET'
            break
        }
        req.method = method

        if (params) {
          if (params.constructor && params.constructor === FormData) {
            data = params
          } else {
            data = params
          }
        }

        // adding operation to data set
        if (op) {
          if (typeof data.append === 'function') {
            data.append('op', op)
          } else {
            data.op = op
          }
        }

        req.data = data

        format = format ? format.toString().toLowerCase() : format
        switch (format) {
          case 'json':
          case 'xml':
          case 'text':
            // do nothing -- everything is already purrrrfect.
            break
          case false:
            if (format === false) { break }
          default:
            format = 'json'
            break
        }
        req.dataType = format

        if (progressCb) {
          req.xhr = function () {  // Custom XMLHttpRequest
            var myXhr = $.ajaxSettings.xhr()
            if (myXhr.upload) { // Check if upload property exists
              myXhr.upload.addEventListener('progress', progressCb, false) // For handling the progress of the upload
            }
            return myXhr
          }

          req.processData = false
          req.contentType = false
        }

        // disable cache
        req.cache = false
        if (req.url.indexOf('?') != -1) {
          req.url += '&_=' + Date.now()
        } else {
          req.url += '?_=' + Date.now()
        }

        return req

      },
      query: function (req, successCb, errorCb) {

        if (!errorCb)
          errorCb = function () {}

        //console.log(LOG_LABEL, 'req', req);

        switch (req.method) {
          case 'GET':
          case 'HEAD':
          case 'DELETE':

            if (req.url.match(/\?/)) {
              req.url += '&' + _fn.utils.objToUrlParam(req.data)
            } else {
              req.url += '?' + _fn.utils.objToUrlParam(req.data)
            }

            delete (req.data)

            break
          case 'POST':
          case 'PUT':
          default:

            if (req.data) {
              //req.data = _fn.utils.objToUrlParam(req.data);
            }
            break
        }

        var apiCall = function (retrying) {

          req.success = function (data, status, xhr) {
            if (xhr.status != 200) {
              console.error(LOG_LABEL, {
                log: data.message,
                url: req.url,
                data: data,
                status: status,
                xhr: xhr
              })
              errorCb(data, status, xhr)
              return false
            }

            //console.info({
            //    log: 'query succ',
            //    url: req.url,
            //    data: data,
            //    status: status,
            //    xhr: xhr
            //});

            successCb(data, status, xhr)
          }

          req.error = function (xhr, status, errorMsg) {
            //console.warn(LOG_LABEL, {
            //    log: 'query failed: ' + errorMsg,
            //    url: req.url,
            //    data: errorMsg,
            //    status: status,
            //    xhr: xhr
            //});

            errorCb(errorMsg, xhr.status, xhr, req)
          }

          $.ajax(req)

        }

        apiCall()

      }
    },
    fn = {
      echo: function (echoText, succCb, errCb) {

        var uri = _fn.utils.createApiCallUri(),
          data = { echo_text: echoText }

        if (uri === false) { // dont echo on some screens, e.g. play-final -> uri is false -> return fake success.
          return succCb(echoText, null, null)
        }

        data.survey_name = ENV.survey_name

        var req = _fn.prepReq(uri, 'GET', 'echo', data, 'text')

        _fn.query(req, function (dataraw, status, xhr) {
          var res
          try {
            res = (typeof dataraw === 'object' ? dataraw : JSON.parse(dataraw)).data
          } catch (err) {
            // console.warn(LOG_LABEL, 'old format;', dataraw)
            res = dataraw.substr(0, echoText.length)
          }

          if (res === echoText) {
            succCb(res, status, xhr)
          } else {
            console.warn(LOG_LABEL, 'FAILED; returned value not matching expected value', echoText, res)
            errCb(dataraw, xhr.status, xhr, req)
          }
        }, errCb)

      },
    }

  return fn
});
