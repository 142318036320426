!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('app/helper/events.se',[
      'mitt'
    ], factory)
  }
}(
  /**
   * @param {mitt} mitt
   * @returns {SeEventBus}
   */
  function (
    mitt
  ) {
    if(typeof window !== 'undefined'){
      window.SE = window.SE || {}
    }
    var
      SE = typeof window !== 'undefined' && window.SE,
      /**
       * @type {mittInstance}
       */
      eventbus = SE && SE.EVENTS || mitt()

    if(SE && !SE.EVENTS){
      SE.EVENTS = eventbus
    }

    return  eventbus
  }
);

// TYPES ---------------------------------------------------------------

/**
 * @typedef mittInstance
 * @property {function} on
 * @property {function} off
 * @property {function(type: string, data: object|any)} emit
 * @property {Map} all
 */
/**
 * @typedef mitt
 * @returns {mittInstance}
 */
/**
 * @typedef SeEventBus
 * @type mittInstance
 */

/**
 * @typedef ApiErrorEventData
 * @property {string} op api op name
 * @property {object|any} data error data
 * @property {number} status
 * @property {XMLHttpRequest} xhr
 */;
