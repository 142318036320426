!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('app/config/defaults',[], factory)
  }
}(function () {

  var DEFAULTS = {
    ENV: {
      USER_IS_ACTIVE_DELAY_MS: function (ENV) {
        if (!isNaN(parseInt(ENV.auto_save_seconds)) && ENV.auto_save_seconds !== -1) {
          return parseInt(ENV.auto_save_seconds) * 1000
        }
        return 2000
      },
      MAX_UPLOAD_USER: 20,
      MAX_UPLOAD_RESPONDENT: 20,
      API_BASE_URL: function () { return '//' + window.location.host + '/bin/api.pl' },

      subscription_expired: false,
      ping_rate: 1,
      live_ngene: 0
    }
  }

  return {
    DEFAULTS: DEFAULTS,
    loadDefaults: loadDefaults,
    load: loadDefaultsKey
  }

  function loadDefaultsKey (obj, key) {
    return loadDefaults(obj, DEFAULTS[key])
  }

  /**
   * loads default values for not-set-yet keys into `obj`
   * @param {object} obj
   * @param {object} defaultsObj
   */
  function loadDefaults (obj, defaultsObj) {
    Object.keys(defaultsObj).map(function (k) {
      var defaultVal = defaultsObj[k]
      if (typeof obj[k] === 'undefined' || obj[k] === null || obj[k] === '') {
        if (typeof defaultVal === 'function') {
          obj[k] = defaultVal(obj)
        } else {
          obj[k] = defaultVal
        }
      }
    })

    return obj
  }

});
