define('play_run',[
    'app.env',
    'app/app.utils',
    'app/app.status',
    'app/app.view'

  ],
  function (
    env,
    utils,
    status,
    view
  ) {

    var app = {} // = window.app = window.app || {};

    app.env = env

    app.utils = utils

    app.status = status

    app.view = view

    app.initView = function () {
      // if app is < initialized
      if (app.status.is() < 2) return

      // else -> set ui-loaded and init
      app.status.set(3)

      // initialize view
      app.view.init()

    }

    app.init = function () {
      console.debug('app init.')
      app.status.set(1)
      app.view.load(env, status)
      app.status.set(2)

      $(function () {
        // and as soon as the document is loaded -> try to init view
        app.initView()
      })

    }

    $(function () {
      // as soon as the document is loaded -> try to init view
      app.initView()
    })

    return app

  }
);
