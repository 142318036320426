define('jqueryUi',[
    "jquery",
    "jqueryUiBase",
    "jqueryUiTouch"
], function(
    _$,
    _$ui,
    _$uiTouch
){

});
