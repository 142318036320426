!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('backboneMdl',[
      'lodash',
      'backbone',
      'app/config/constants'
    ], factory)
  }
}(
  /**
   * @param _
   * @param Backbone
   * @param CONSTANTS
   * @returns {{Model: BackboneBaseModel, Collection: BackboneBaseCollection}}
   */
  function (
    _,
    Backbone,
    CONSTANTS
  ) {
    /**
     * Backbone Model Wrapper Class
     */

    var LOG_LABEL = 'BCKBN BASE'

    var STATUS = CONSTANTS.STATUS

    var
      /**
       * @typedef BackboneBaseModel
       * @extends Backbone.Model
       */
      /**
       * @type BackboneBaseModel
       */
      BaseModel = Backbone.Model.extend(/** @lends BaseModel.prototype */{
          isSurveyEngineMdl: true,  // no purpose - just telling.
        /**
         * @constructs
         */
          initialize: function () {
            var _this = this

            _this.initStatus()

            _this.set('cid', _this.cid)

            if (_this.init) {
              _this.init.apply(_this, arguments)
            }

          },

          clone: function () { // overwrite clone to be a deep clone of the attributes!
            var _this = this,
              thisModel = _this.constructor,
              clonedAttributes = {}

            _.forEach(_this.attributes, function (val, attr) {
              switch (typeof val) {
                case 'object':
                  try {
                    val = JSON.parse(JSON.stringify(val))
                  } catch (err) {
                    console.error('error on cloning object via JSON', _this.attributes)
                  }
                  break
                default:
                  // no change
                  break
              }

              clonedAttributes[attr] = val

            })

            return new thisModel(clonedAttributes)
          },

          /**
           * creates a JSON only from the attributes that are differend to the default settings
           * @returns {{}}
           */
          toDiffJSON: function () {
            var _this = this

            var _thisDiffJson = {},
              //_thisDefaults = typeof(_this.__proto__.defaults) == "function" ? _this.__proto__.defaults() : _this.__proto__.defaults,
              _thisDefaults = typeof (_this.defaults) == 'function' ? _this.defaults() : _this.defaults

            _.forEach(_this.attributes, function (val, key) {
              if (val && val.toDiffJSON) {
                val = val.toDiffJSON()
              } else if (val && val.toJSON) {
                val = val.toJSON()
              }

              var tmpDefaultVal = _thisDefaults[key]
              if (tmpDefaultVal && tmpDefaultVal.toDiffJSON) {
                tmpDefaultVal = tmpDefaultVal.toDiffJSON()
              } else if (tmpDefaultVal && tmpDefaultVal.toJSON) {
                tmpDefaultVal = tmpDefaultVal.toJSON()
              }

              if (tmpDefaultVal != undefined &&
                !_.isEqual(tmpDefaultVal, val)) {
                _thisDiffJson[key] = val
              }
            })

            return _thisDiffJson
          },

          // status

          status: null,
          initStatus: function () {
            var _this = this
            /*
             TEMPORARY:  "10-temporary",

             LOADING:    "20-loading",
             LOADED:     "21-loaded",

             READY:      "22-ready",
             RESET:      "23-reset",

             RUNNING:    "25-running",
             PAUSED:     "26-paused",
             STOPPED:    "27-stopped",

             CHANGED:    "30-changed",
             SAVING:     "31-saving",
             SAVED:      "32-saved",

             DELETED:    "50-deleted",
             DESTROYED:  "51-destroyed",
             FAILED:     "52-failed"
             */

            var defaultStatus = STATUS.TEMPORARY

            if (_this.defaults &&
              _this.defaults.status) {

              defaultStatus = _this.defaults.status

              if (!_.findKey(STATUS, function (stat) { return stat == defaultStatus })) {
                console.log(LOG_LABEL, 'unexpected default status; status handling not set for this model', _this, defaultStatus)
                return
              }
            }

            _this.setStatus(defaultStatus) // default.

            _this.on('isTemporary', function () {
              _this.setStatus(STATUS.TEMPORARY)
            })

            _this.on('isLoading', function () {
              if (_this.status < STATUS.LOADING) {
                _this.setStatus(STATUS.LOADING)
              }
            })
            _this.on('hasLoaded', function () {
              if (_this.status < STATUS.LOADED) {
                _this.setStatus(STATUS.LOADED)
              }
            })
            _this.on('isReady', function () {
              if (_this.status < STATUS.READY) {
                _this.setStatus(STATUS.READY)
              }
            })
            _this.on('hasChanged', function () {
              _this.setStatus(STATUS.CHANGED)
            })
            _this.on('isSaving', function () {
              _this.setStatus(STATUS.SAVING)
            })
            _this.on('hasSaved', function () {
              if (_this.status != STATUS.CHANGED) {
                _this.setStatus(STATUS.SAVED)
              }
            })
            _this.on('isDeleted', function () {
              if (_this.status < STATUS.DELETED) {
                _this.setStatus(STATUS.DELETED)
              }
            })
            _this.on('isDestroyed', function () {
              if (_this.status < STATUS.DESTROYED) {
                _this.setStatus(STATUS.DESTROYED)
              }
            })
            _this.on('hasFailed', function () {
              if (_this.status < STATUS.FAILED) {
                _this.setStatus(STATUS.FAILED)
              }
            })
          },
          setStatus: function (newStatus) {
            var _this = this

            if (!newStatus || !_.find(STATUS, function (status) { return status == newStatus })) {
              console.error(LOG_LABEL, 'status missing / wrong status', newStatus, STATUS)
              return
            }

            //if(newStatus == _this.status)
            //    return;

            var oldStatus = _this.status
            _this.status = newStatus
            _this.set('status', newStatus, { silent: true })

            _this.trigger('status:' + newStatus, _this, newStatus, oldStatus)
            _this.trigger('status', _this, newStatus, oldStatus)

            if (_this.collection) {
              _this.collection.trigger('status', _this, newStatus, oldStatus)
            }
          },
          /**
           * @deprecated use attribute this.status instead
           */
          getStatus: function () {},

          /**
           * returns the true current position of an element in the collection
           * ignoring deleted elements
           * @return {number|null}
           */
          getPosition: function () {
            var _this = this
            if (!_this.collection) return null

            return _this.collection.getElementsOrder().indexOf(_this.cid)
          },

          /**
           * clone set - clones values obj before returning it
           * @param key
           * @param val
           * @param options
           * @return {*}
           */
          cset: function (key, val, options) {
            var _this = this

            if (typeof key == 'string') {
              val = _.cloneDeep(val)

              return _this.set(key, val, options)
            } else {

              options = val
              var attrs = key,
                res

              _.each(attrs, function (val, key) {
                res = _this.cset(key, val, options)
              })

              return res
            }

          },
          cget: function (attr) {
            var _this = this
            /**
             * clone get
             * clones result obj before returning it
             */

            return _.cloneDeep(_this.get(attr))
          },
          pget: function (path) {
            var _this = this

            if (!path) {
              return _.cloneDeep(_this.attributes)
            }

            var pathArr = path.split('.'),
              firstAttr = pathArr.shift(),
              attr = _this.cget(firstAttr)

            if (pathArr.length == 0) {
              return attr
            }

            return _.get(attr, pathArr)

          },
          pset: function (path, val, options) {
            var _this = this

            var cval = _.cloneDeep(val)

            var pathArr = path.split('.'),
              firstAttr = pathArr.shift(),
              attr = _this.cget(firstAttr)

            if (pathArr.length == 0) {
              attr = val

            } else {
              if (!attr || typeof (attr) != 'object') {
                attr = {}
              }

              attr = _.set(attr, pathArr, cval)
            }

            _this.set(firstAttr, attr, options)
          },
          merge: function (path, val, options) {
            var _this = this

            if (!_.isString(path)) {
              options = val
              val = path
              path = null
            }

            var attr = _this.pget(path)

            attr = _.merge({}, attr, val)

            if (!path) {
              _this.set(attr, options)
            } else {
              _this.pset(path, attr, options)
            }

          }

        }
      )

    var
      /**
       * @typedef BackboneBaseCollection
       * @extends Backbone.Collection
       */
      /**
       * @type BackboneBaseCollection
       */
      BaseCollection = Backbone.Collection.extend(/** @lends BaseCollection.prototype */{
      isSurveyEngineColl: true,
      comparator: 'position',
      model: BaseModel,
        /**
         * @constructs
         */
      initialize: function () {
        var _this = this

        _this.initStati()

        _this.on('sort', function (coll, options) {
          if (_this._hasNewOrder()) {
            _this.trigger('sorted', coll, options)
          }
        })

        if (_this.init) {
          _this.init.apply(_this, arguments)
        }
      },

      // stati
      initStati: function () {
        var _this = this

        _this.on('status', function (mdl, status) {
          _this._updateStati()
        })
      },
      stati: {},
      _updateStati: function () {
        var _this = this
        var active = 0,
          newStati = _.countBy(_this.models, function (mdl) {
            if (mdl.status >= STATUS.LOADED && mdl.status <= STATUS.DELETED)
              active++
            return mdl.status
          })

        newStati.active = active
        _this.stati = newStati

        newStati.total = _this.length

        _this.trigger('stati', _this, newStati)
      },

      // internal
      _order: '',
      _hasNewOrder: function () {
        var _this = this

        var curOrder = _this.pluck('cid').join(':')
        if (curOrder == _this._order)
          return false

        _this._order = curOrder
        return true
      },

      _updateIdReference: function (oldId, newId, options) {
        var _this = this

        options = _.merge({
          keepOldReference: true
        }, options || {})

        if (_this._byId[oldId]) {
          _this._byId[newId] = _this._byId[oldId]
        }

        if (!options.keepOldReference) {
          delete (_this._byId[oldId])
        }
      },

      // actions

      getNotDeletedElements: function () {
        var _this = this
        return _this.filter(function (mdl0) { return !mdl0.isDeleted })
        //return _.filter(_this.toArray(), function(model){ return !model.isDeleted;})
      },

      getNotDeletedElementsCount: function () {
        var _this = this,
          elms = _this.getNotDeletedElements()

        return elms.length
      },

      getElementsOrder: function () {
        var _this = this

        return _.map(_this.getNotDeletedElements(), function (model) { return model.cid })
      },

      fetchAll: function (options) {
        var _this = this

        options = _.merge({
          delayed: 0,
          max: -1
        }, options || {})

        var
          fetchDelay = options.delayed || 0,
          fetchInterval = 1,
          maxPages = options.max ? options.max : -1

        _this.each(function (child) {
          if (!options.forced && child.status >= STATUS.LOADING) {
            return
          }

          if (maxPages === 0) return
          maxPages--

          setTimeout(function () {
            child.fetch(options)
          }, fetchDelay * fetchInterval)

          fetchInterval++
        })
      },
      /**
       * causes all child models to call a save()
       * @param {Object} [options] - forwared to childMdl.save(options)
       */
      saveAll: function (options) {
        var _this = this
        _this.each(function (child) {
          child.save(options)
        })
      },

      moveChildAfter: function (childCid, afterChildCid, options) {
        var curPos = this.get(childCid).getPosition(),
            afterChildMdl = afterChildCid && this.get(afterChildCid),
            newPos = afterChildMdl ?
              // set position AFTER afterChildMdl position
              afterChildMdl.getPosition() + 1
              // else set to first position
              : 0

        if(curPos < newPos){
          // adjust for "to be removed before newPos" self offset by 1
          newPos--
        }

        return this.moveChildToPos(childCid, newPos, options)
      },
      // -- not used
      // moveChildBefore: function (childCid, nextToCid, options) {
      //   var newPos = this.get(nextToCid).getPosition()
      //   return this.moveChildToPos(childCid, newPos, options)
      // },

      /**
       *
       * @param {string} childCId
       * @param {number} newPos
       * @param { {silent?: boolean} } [options={silent:false}]
       */
      moveChildToPos: function (childCId, newPos, options) {
        var _this = this

        var _childCId = _this.get(childCId.cid || childCId).cid,
            _newPos = +newPos,
            _options = _.merge({
              silent: false
            }, options || {})

        var tmpOrder = _this.getElementsOrder(), // only non-deleted elements
          curPos = tmpOrder.indexOf(_childCId)

        if(curPos === _newPos){
          return
        }

        // if childCid is part of the order
        if (curPos !== -1) {
          // remove element from old position
          tmpOrder.splice(curPos, 1)

          // if newPos is beyond (removed) curPos + 1
          // e.g. cP: 1, nP: 3
          // if (_newPos > (curPos + 1)) {
          //   // adjust newPos for removed element
          //   _newPos -= 1
          // }
        }
        // insert at new position
        tmpOrder.splice(_newPos, 0, _childCId)

        // reset positions
        _.forEach(_this.getNotDeletedElements(),
            /**
             * @param {Model} child
             */
            function (child) {
          var tmpPos = tmpOrder.indexOf(child.cid)
          child.set('position', tmpPos, {silent: true})
        })

        _this.sort({ silent: _options.silent })

      },

      /**
       * returns the next mdl in the list or null (if this is already the last model)
       * @param {string|Object}   mdlOrId     model object or object id/cid
       * @param {object}          [options]   {filter: function, cyclic: boolean}
       * @param {function}        [options.filter] function that takes the possible next model as param, e.g. filterFn(mdl){ return mdl.deleted == false; }
       * @param {boolean}         [options.cyclic=false] continues with the first after the last
       *
       * @returns {Object|null} model object       - next model object or null
       *
       * @see {@link BaseCollection.prev}
       */
      next: function (mdlOrId, options) {
        var _this = this,
          _models = _this.models,
          isNext = false,
          nextMdl = null

        options = _.merge({
          filter: function (mdl) { return true },
          cyclic: false
        }, options || {})

        var mdlId = mdlOrId.id || mdlOrId

        _models.forEach(function (mdl0) {
          if (nextMdl) return
          if (isNext && options.filter(mdl0)) {
            nextMdl = mdl0
            return
          }
          if (mdl0.id == mdlId) {
            isNext = true
          }
        })

        if (options.cyclic && !nextMdl) {

          isNext = true

          _models.forEach(function (mdl0) {
            if (nextMdl) return
            if (isNext && options.filter(mdl0)) {
              nextMdl = mdl0
              return
            }
            if (mdl0.id == mdlId) {
              isNext = false
            }
          })

        }

        return nextMdl
      },

      /**
       * returns the next mdl in the list or null (if this is already the last model)
       * @param   {Object|string}     mdlOrId     -
       * @returns {Object|null}       mdl or null -
       *
       * @see {@link BaseCollection.next}
       *
       */
      prev: function (mdlOrId) {
        var _this = this,
          _models = _this.models,
          wasPrev = false,
          prevMdl = null

        var mdlId = mdlOrId.id || mdlOrId

        _models.forEach(function (mdl0) {
          if (wasPrev) return
          if (mdl0.id == mdlId) {
            wasPrev = true
            return
          }
          prevMdl = mdl0
        })
        return prevMdl
      }
    })

    return {
      Model: BaseModel,
      Collection: BaseCollection
    }

  }
)
;
