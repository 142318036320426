!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('app/helper/copy',[
      'lodash',
      'jquery',
      'toasties'
    ], factory)
  }
}(function (_, $, Toasties) {

  var LOG_LABEL = 'CPY'

  var _v = {
      popups: {}
    },
    _fn = {
      initCopyThisButton: function () {

        $(window)
          .off('click', _fn.onClickCopyThisButton)
          .on('click', _fn.onClickCopyThisButton)

      },
      onClickCopyThisButton: function (ev) {
        var copyThisElm = $(ev.target).attr('data-copy-this') ? $(ev.target) : $(ev.target).closest('[data-copy-this]')

        if (!copyThisElm.size()) return

        var msg = decodeURIComponent(copyThisElm.attr('data-copy-this'))

        fn.copyToClipboard(msg)

      }
    },
    fn = {
      createCopyThisButton: function (message, options) {
        var defaultOptions = {
          class: 'btn',
          title: 'Copy to clipboard',
          label: '<i class="fa fa-copy"></i>'
        }

        options = _.merge(defaultOptions, options)

        var btn = $('<button/>')
        btn.html(options.label)
        btn.attr('title', options.title)
        btn.attr('data-copy-this', encodeURIComponent(message))
        btn.addClass(options.class)

        return btn.get(0).outerHTML

      },
      copyToClipboard: function (obj, message) {
        var tmpField,
          val,
          copied

        message = message || 'Copied to clipboard'

        // if obj is valid selector or element copy its value or contents -- otherwise treat as string
        try {
          val = $(obj).val() || $(obj).html() || $(obj).text()
        } catch (e) {}

        if (!val) {
          val = '' + obj
        }

        if (!val) {
          console.warn(LOG_LABEL, 'copyToClipboard', 'nothing found to copy', obj, val)
          return
        }

        if (document.execCommand) {
          tmpField = $('<textarea data-role="clipboard-field" style="width: 1px; height: 1px; overflow: hidden; position: fixed; top: -1px; left: -1px;"></textarea>')
          tmpField.val(val)
          $('body').append(tmpField)

          tmpField.select()

          try {
            copied = document.execCommand('copy')
          } catch (err) {}

          $(tmpField).remove()

          if (copied) {
            console.log(LOG_LABEL, 'copied to clipboard', val)
            Toasties.tiny(message)
            return true
          }

        } else {
          window.prompt('Copy to clipboard: Ctrl+C or Cmd+C, Enter', val)

        }

        return false
      }
    }

  _fn.initCopyThisButton()

  return fn

});
