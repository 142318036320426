require([
    "rjs.init", "play_run"
], function (
    rjsinit, app
) {

    // run app.init();
    app.init.apply(app);

});
define("sePlay", function(){});

