;!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('app/app.utils',[
      'app.env',
      'lodash',
      'jquery',
      'toasties',
    ], factory)
  }
}(
  /**
   * @param {AppEnv} ENV
   * @param {lodash} _
   * @param {jQuery} $
   * @param {SeToasties} Toasties
   * @returns {SeUtils}
   */
  function (
    ENV,
    _,
    $,
    Toasties
  ) {
    var LOG_LABEL = 'utils'

    var _v = {
        popups: {}
      },
      _fn = {},
      /**
       * @typedef {{appendScripts: (function(*, *, *, *): *), charts: (function(*): {}), hasTouch: (function(): *), watchFields: (function(*): {add: function(*): void, triggerEdit: function(*): void, hasChanges: function(): boolean, resetState: function(): void}), tooltip: UTILS.tooltip, dispatchCustomEvent: UTILS.dispatchCustomEvent, obj2str: ((function(*, *): *)|*), disarmScript: (function(*): *), _jQValue, _jQShadowHtml, removeUriBase: (function(*, *): *), hasScript: ((function(*): (*|boolean))|*), removeFadeOutElement: (function(*, *): {cancel: function(): void}), automaxheight: (function(*, *): {update: function(): void, teardown: function(): void}), hashCode: ((function(): (number))|*), openPopup: ((function(*, *, *): boolean)|*), addUriBase: (function(*, *): *), appendStyleFile: (function(HTMLLinkElement): Element), waitFor: (function(function(): T, number=, function()=, function()=, number=): *), appendStyle: (function(HTMLStyleElement): Element), removeNode: UTILS.removeNode, stripHtmlTags: (function(string): string), rearmScript: (function(*): *), objHash: ((function(*): (*|undefined))|*), cleanupSemyEmptyHtml: ((function(*): *)|*), _jQisHovered, addFadeInElement: UTILS.addFadeInElement, modals: Object, cloneObjBare: ((function(*): *)|*), cloneObjNoLoop: ((function(*, *): ({}|*|undefined))|*), async, escapeHTML: (function(*): string), _jQAttributes, once, isEmptyArtificialParagraph: (function((HTMLElement|string)): *), createUri: ((function(string, Object): string)|*), createXmlElement: (function(*, *): jQuery), testScriptForSyntaxError: ((function(string): Error)|*), appendScript: (function(HTMLScriptElement, HTMLElement): *)}} SeUtils
       */
      /**
       * @type SeUtils
       * @kind object
       */
      UTILS = {
        /**
         * @param {string} htmlStr
         * @returns {string}
         */
        stripHtmlTags: function(htmlStr) {
          var dom = document.createElement('div')
          dom.innerHTML = htmlStr
          return dom.innerText
        },

        /**
         * adds parameters to base uri with or without search string
         * @param {string} baseUri
         * @param {object} params
         * @return {string} uri with parameters as search string attached
         */
        createUri: function(baseUri, params) {
          if (!params) {
            return baseUri
          }

          var
            baseUriInfo = baseUri.split('?'),
            _baseUri = baseUriInfo[0],
            _params = params || {},
            paramsSearchStr

          if (baseUriInfo[1]) {
            baseUriInfo.split('&').map(function(val) {
              var valArr = val.split('=')
              _params[valArr[0]] = decodeURIComponent(valArr[1])
            }, {})
          }

          paramsSearchStr = Object.keys(params).map(function(key) {
            return key + '=' + encodeURIComponent(params[key])
          }).join('&')

          return _baseUri + '?' + paramsSearchStr
        },
        /**
         * removes trailing EmptyArtificialParagraphs from html string - or empties the string if this is all there is.
         * @param htmlStr
         * @returns {*}
         */
        cleanupSemyEmptyHtml: function(htmlStr) {
          var $tmpDom = $('<div/>').shadowhtml(htmlStr),
            $lastElm = $tmpDom.children().last()

          if (UTILS.isEmptyArtificialParagraph($lastElm)) {
            $lastElm.remove()
            return UTILS.cleanupSemyEmptyHtml($tmpDom.shadowhtml())
          }

          // remove left-over "<br>" from the end of
          var brAtTheEndOfTheHtmlStrRegExp = new RegExp('(<br\\/?>\\s*)(<\\/(div|p)>)?$', 'i')
          if ($lastElm[0] && $lastElm[0].outerHTML.match(brAtTheEndOfTheHtmlStrRegExp)) {
            htmlStr = htmlStr.replace(brAtTheEndOfTheHtmlStrRegExp, '$2')
          }

          return htmlStr
        },
        /**
         * compares htmlStr if it is <div.editor_p><br></div> - independent of linebreaks or additional blanks
         *
         * summernote adds such elements as a base - those have to be found and removed
         *
         * @param {HTMLElement|string} htmlNodeOrStr
         */
        isEmptyArtificialParagraph: function(htmlNodeOrStr) {
          var $tmpDom = $('<div/>').shadowhtml(htmlNodeOrStr),
            nrOfElements = $tmpDom.find('*').length,
            hasOnlyParagraphsAndLinebreaks = nrOfElements === $tmpDom.find('div.editor_p, br, p').length,
            hasNoText = $tmpDom.text().trim() === ''

          // reset content to empty string "" if it holds no image and no text
          return nrOfElements && hasOnlyParagraphsAndLinebreaks && hasNoText
        },
        /**
         * contains <script> and <style> elements by wrapping them into textarea elements
         * @param armedHtmlStr
         * @return {string}
         */
        disarmScript: function(armedHtmlStr) {
          /**
           *
           * @type {*|jQuery|HTMLElement}
           */
          var $shadowDom = $('<div/>')

          $shadowDom.shadowhtml(armedHtmlStr)

          $shadowDom.find('script').each(function(i, elm) {
            if ($(elm).attr('src')) {
              $(elm).wrap($('<textarea readonly class="code linkedfile"/>'))
            } else {
              $(elm).wrap($('<textarea readonly class="code"/>'))
            }
          })
          $shadowDom.find('style').wrap($('<textarea readonly class="code css"/>'))

          $shadowDom.find('link').wrap($('<textarea readonly class="code css linkedfile"/>'))

          $shadowDom.find('iframe').wrap($('<textarea readonly class="code iframe"/>'))

          return $shadowDom.shadowhtml().trim()

        },
        /**
         * unwraps <style> and <script> elements that were previously "disarmed" using the disarmScript() method
         * @param disarmedHtmlStr
         * @return {*}
         */
        rearmScript: function(disarmedHtmlStr) {
          /**
           * @type {*|jQuery|HTMLElement}
           */

          var $shadowDom = $('<div/>')

          $shadowDom.shadowhtml(disarmedHtmlStr)

          $shadowDom.find('textarea[readonly].code').each(function(i, elm) {
            elm = $(elm)

            // sometimes the editor wraps the textareas in <p> or other elements.
            if ((elm.parent().hasClass('editor_p') || elm.parent()[0].attributes.length === 0) &&
              elm.parent().text().trim() === elm.text().trim() &&
              !elm.parent().is($shadowDom)) {
              elm.unwrap()
            }

            elm.replaceWith($(elm.val()))
          })

          return $shadowDom.shadowhtml()

        },

        /**
         * escapes an HTML string, e.g. "<TAG>" --> "&lt;TAG&GT;"
         * @param htmlStr
         * @returns string
         */
        escapeHTML: function(htmlStr) {
          var escape = window.__tmpTextareaElmEscape = window.__tmpTextareaElmEscape || document.createElement('textarea')
          escape.textContent = htmlStr
          return escape.innerHTML
        },

        /**
         * prefixes relative href= and src= attribute values for .jpeg, .jpg, .gif, .bmp, .ico, .png, .apng files with the $assets_uri
         * @param str
         * @param assets_uri
         * @returns {*}
         */
        addUriBase: function(str, assets_uri) {
          str = UTILS.removeUriBase(str, assets_uri)

          if (str && str.replace) {
            // find all relative (!) urls and prepend assets_uri
            str = str.replace(/(src|href)(s*=s*['"]?)([^:>]+\.)(jpe?g|gif|bmp|ico|a?png)/igm, "$1$2" + assets_uri + "$3$4")
          }
          return str
        },
        /**
         * removes $assets_uri from href= and src= attribute values
         * @param str
         * @param assets_uri
         * @returns {*}
         */
        removeUriBase: function(str, assets_uri) {
          if (typeof str !== 'undefined' && str.replace)
            str = str.replace(new RegExp('(src|href)(\s*=\s*[\'"]?)' + assets_uri, 'g'), '$1$2')

          return str
        },

        /**
         * creates an XML element based on tagName and attributes;
         * uses jQuery, returns new generated element
         * @param elementTagName
         * @param attributesObj
         * @returns {*|jQuery}
         */
        createXmlElement: function(elementTagName, attributesObj) {
          var
            /** @type jQuery */
            $newXmlElm = $(elementTagName, $.parseXML('<' + elementTagName + '/>'))

          if (attributesObj) {
            $newXmlElm.attr(attributesObj)
          }

          return $newXmlElm
        },

        /**
         * simply checks for '<script'
         * @param htmlStr
         * @return {*|boolean}
         */
        hasScript: function(htmlStr) {
          if (htmlStr && htmlStr.match)
            return htmlStr.match(/<script/)

          return false
        },

        /**
         *
         * @param $elm
         * @param callback
         */
        addFadeInElement: function($elm, callback) {
          callback = callback || function() {}

          var elmStyles = $elm.attr('style') || '',
            classAnimAddFadein = 'anim_addFadeIn'

          $elm.css({
            position: 'absolute',
            visibility: 'hidden',
            width: $elm.parent().innerWidth(),
            height: 'auto'
          })

          var elmHeight = $elm.height()

          $elm.css({
            height: 0 + 'px',
            'min-height': 0,
            overflow: 'hidden',
            opacity: 0
          })
          $elm.addClass(classAnimAddFadein)

          $elm.css({
            position: 'inherit',
            visibility: 'visible',
            height: elmHeight + 'px',
            width: 'auto',
            opacity: 1
          })

          setTimeout(function() {
            setTimeout(function() {
              $elm.removeClass(classAnimAddFadein)
              $elm.attr('style', elmStyles)

              callback()
            }, 650) // double delay as animation duration (currently 0.3s)
          }, 2)

        },
        /**
         *
         * @param $elm
         * @param callback
         * @return {{cancel: cancel}}
         */
        removeFadeOutElement: function($elm, callback) {
          callback = callback || function() {}

          var classAnimRemoveFadeout = 'anim_removeFadeOut',
            elmHeight = $elm.outerHeight(),
            elmStyles = $elm.attr('style') || ''

          $elm.css({
            display: 'block',
            height: elmHeight + 'px'
          })

          var delayedCallbackFn = function() {

            $elm.addClass('hidden')

            setTimeout(function() {
              callback()

              $elm.removeClass(classAnimRemoveFadeout)
              $elm.attr('style', elmStyles) // resetting
            }, 200)

          }

          setTimeout(function() {
            $elm.addClass(classAnimRemoveFadeout)
            setTimeout(delayedCallbackFn, 199)// same delay as animation duration (currently 0.2s) (works more reliable than 'transitionend' event
          }, 2) // slight delay, otherwise the transition animation is not properly triggered

          return {
            cancel: function() {
              $elm.removeClass('hidden')
              $elm.removeClass(classAnimRemoveFadeout)
              $elm.attr('style', elmStyles) // resetting
            }
          }

        },
        /**
         *
         * @param url
         * @param windowName
         * @param doNotRetry
         * @return {boolean}
         */
        openPopup: function(url, windowName, doNotRetry) {
          windowName = windowName || 'popup0'

          var popup = _v.popups[windowName] || null,
            pos = ''

          if (popup && !popup.closed) {
            pos = 'left:' + popup.screenX + ',top:' + popup.screenY + ','
            popup.close()
          }

          try {
            popup = _v.popups[windowName] = window.open(url, windowName, pos + 'width=1020,height=600,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes')
          } catch (err) {
            // unsuccessfull
          }

          if (popup) {
            setTimeout(function() {

              if (!doNotRetry) {
                setTimeout(function() {
                  if (document.hasFocus()) {
                    UTILS.openPopup.apply(_fn, [url, windowName, true])
                  }
                }, 100)
              }

              popup.focus()

            }, 10)
          } else {
            console.warn(LOG_LABEL, 'popup blocked')
            Toasties.warning('Popup was blocked.')
            return false
          }

          return true
        },
        /**
         *
         * @param node
         * @param additionalOffset
         * @return {{update: (function(): void), teardown: teardown}}
         */
        automaxheight: function(node, additionalOffset) {

          var maxHeightFixedClass = 'maxHeightFixed'

          additionalOffset = additionalOffset || 0

          var
            wrapperElm = $(node),
            delayAutomaxheight,

            bottomOffset = (function() {
              var $tmpElm = wrapperElm.parent(),
                bottomOffset = 0

              while ($tmpElm.parent().size()) {
                bottomOffset += parseFloat($tmpElm.css('padding-bottom'))
                bottomOffset += parseFloat($tmpElm.css('margin-bottom'))

                $tmpElm = $tmpElm.parent()
              }

              return bottomOffset
            }()),

            resizeWrapperFn = function() {

              var posTop = wrapperElm.offset().top,
                vpHeight = $(window).innerHeight(),
                unknownOffset = 0,
                wrapperHeight = vpHeight - posTop - bottomOffset - unknownOffset - additionalOffset

              //console.log("editpage", "window", "resize", wrapperHeight);

              wrapperElm
                .css('height', wrapperHeight)
                .css('overflow', 'hidden')
                .addClass(maxHeightFixedClass)

              $(wrapperElm).children()
                .css('height', '100%')
                .css('overflow', 'auto')

            }

          $(window).on('resize', resizeWrapperFn)
          $('body').on('view.rendered', resizeWrapperFn)

          delayAutomaxheight = setTimeout(resizeWrapperFn, 100)

          return {
            update: resizeWrapperFn,
            teardown: function() {
              clearTimeout(delayAutomaxheight)

              $(window).off('resize', resizeWrapperFn)
              $('body').off('view.rendered', resizeWrapperFn)
              $(wrapperElm).removeClass(maxHeightFixedClass)
            }
          }

        },
        /**
         * @type Object
         * @property confirm
         */
        modals: {
          /**
           *
           * @param modalId
           * @param succCb
           * @param cancelCb
           */
          confirm: function(modalId, succCb, cancelCb) {
            succCb = succCb || function() {
            }
            cancelCb = cancelCb || function() {
            }

            var modalElm = $('#' + modalId),
              positiveSubmit = false

            $(modalElm).on('hidden.bs.modal', function() {
              if (!positiveSubmit) {
                cancelCb()
              }

              // remove focus from initial button that opened modal
              setTimeout(function(){
                document.activeElement.blur()
              }, 24)
            })

            $('*[data-positive]', modalElm).on('click', function() {
              if (succCb() === false) {
                return
              }

              positiveSubmit = true
              modalElm.modal('hide')
            })

            $('input', modalElm).on('keydown', function(ev) {
              if (ev.key !== 'Enter') return true

              if (succCb() === false) {
                return
              }

              positiveSubmit = true
              modalElm.modal('hide')

            })

          }
        },

        /**
         * adds a simple tooltip to an element using it's attr 'data-tooltip'
         * uses jquery-tooltip-plugin
         * @param {jQuery|HTMLElement} elm
         * @param {string} tooltip
         */
        tooltip: function(elm, tooltip) {
          var _elm = $(elm)

          if (!_elm.data('setooltip')) {
            _elm.data('setooltip', 1)

            $(_elm).tooltip({
              title: tooltip || $(_elm).data('tooltip'),
              placement: 'top',
              trigger: 'hover focus'
            })

            $(_elm).on('click', function() {
              $(_elm).tooltip('hide')
            })
          }

        },
        /**
         * creates a simple svg chart from a DIV using D3
         * @param options - defaultParams: { data: [], scale: [], elm: null,  width: 0,max: null, min: 0 }
         * @return {{}}
         */
        charts: function(options) {
          var chart = (function(options) {
            var // vars
              v = {
                defaultParams: {
                  data: [],
                  scale: [],
                  elm: null,
                  width: 0,
                  max: null,
                  min: 0
                },
                params: null,
                chart: {
                  ref: null
                }
              },
              // private functions
              _fn0 = {
                init: function(options) {
                  v.params = _.defaults(options, v.defaultParams)

                  v.chart.ref = d3.select(v.params.elm) // d3.select(".chart");

                  if (_.isUndefined(v.params.max)) {
                    v.params.max = d3.max(v.params.data)
                  }

                  _fn0.draw()
                },
                draw: function() {

                  var barHeight = 20

                  var width = v.params.width
                  var height = Math.round(v.params.data.length / 2) * barHeight + 10
                  var root = (v.chart.ref)
                    .append('svg')
                    .attr({
                      'width': width,
                      'height': height
                    })

                  var maxDataValue, barWidth, barX, barY

                  if (v.params.min < 0) {

                    maxDataValue = v.params.max

                    barWidth = function(datum) {
                      return Math.round(datum * (width / maxDataValue / 2))
                    }

                    barX = function(datum, index) {
                      if (index % 2) {
                        return Math.round(width / 2) + 1
                      } else {
                        return Math.round(width / 2 - barWidth(datum))
                      }
                    }
                    barY = function(datum, index) {
                      return 5 + Math.floor(index / 2) * (barHeight + 5)
                    }

                    var fillColor = function(datum, index) {
                      var color = (index % 2) ? (v.params.fill || '#dff') : (v.params.fill_neg || '#ffd')
                      // console.log('color ?', color, datum);
                      return color
                    }

                    root.selectAll('svg_bar_bg')
                      .data(v.params.data)
                      .enter()
                      .append('rect')
                      .attr({
                        'class': 'svg_bar_bg',
                        'x': 0,
                        'y': barY,
                        'width': width,
                        'height': barHeight,
                        'fill': '#f2f2f2'
                      })

                    root.selectAll('svg_bar_val')
                      .data(v.params.data)
                      .enter()
                      .append('rect')
                      .attr({
                        'class': 'svg_bar_val',
                        'x': barX,
                        'y': barY,
                        'width': barWidth,
                        'height': barHeight,
                        'fill': fillColor
                      })

                    root.append('rect')
                      .attr({
                        'x': Math.round(width / 2),
                        'y': 0,
                        'width': 1,
                        'height': barHeight + 10,
                        'fill': '#222'
                      })

                  } else {

                    maxDataValue = v.params.max
                    var data = v.params.data

                    barWidth = function(datum) {
                      return datum * (width / maxDataValue)
                    }
                    barY = function(datum, index) {
                      return 5 + index * (barHeight + 5)
                    }

                    root.selectAll('svg_bar_bg')
                      .data(v.params.data)
                      .enter()
                      .append('rect')
                      .attr({
                        'class': 'svg_bar_bg',
                        'x': 0,
                        'y': barY,
                        'width': width,
                        'height': barHeight,
                        'fill': '#f2f2f2'
                      })

                    root.selectAll('svg_bar_val')
                      .data(v.params.data)
                      .enter()
                      .append('rect')
                      .attr({
                        'class': 'svg_bar_val',
                        'x': 0,
                        'y': barY,
                        'width': barWidth,
                        'height': barHeight,
                        'fill': (v.params.fill || '#dff')
                      })

                    root.append('rect')
                      .attr({
                        'x': 0,
                        'y': 0,
                        'width': 1,
                        'height': barHeight + 5 + 5 * data.length,
                        'fill': '#222'
                      })

                  }

                }
              },
              // public functions
              fn = {}

            _fn0.init(options)

            return fn
          }(options))

          return chart
        },

        /**
         * watchFields
         *
         * keeps an eye on fields within given scope and broadcasts edits - used to inform the user on leaving
         * before save or trigger auto-save on changes
         *
         * @param paramsRaw
         * @returns {{add: Function, hasChanges: Function, resetState: Function, triggerEdit: Function}}
         */
        watchFields: function(paramsRaw) {
          var scope = $(),
            params = {},
            _fn = {
              holdFieldState: function(elm) {
                $(elm).data('orig-val', _.clone(_fn.getFieldVal(elm), true))

                console.log('set val', _fn.getFieldVal(elm), $(elm).data('orig-val'))
              },
              getFieldVal: function(elm) {
                if ($(elm).is('input[type=radio], input[type=checkbox]')) {
                  return $(elm)[0].checked
                }

                return $(elm).val()
              },
              hasFieldChanged: function(elm) {
                return _fn.getFieldVal(elm) !== $(elm).data('orig-val')
              },
              onChange: function(elm) {
                if (_fn.hasFieldChanged(elm)) {
                  $(elm).addClass(params.class)
                } else {
                  $(elm).removeClass(params.class)
                }

                _fn.broadcast()
              },
              hasChanges: function() {
                return $(scope).find('.' + params.class).size() > 0
              },
              broadcast: function() {
                console.log('broadcast form changes', _fn.hasChanges(), scope, $(scope).find('.' + params.class))

                if (_fn.hasChanges()) {
                  $(scope).trigger('field_edit')
                  _fn.onEdit()
                } else {
                  $(scope).trigger('field_reset')
                  _fn.onReset()
                }
              },
              onUnloadChanged: function() {
                return 'The form was edited.'
              },
              onEdit: function() {
                if (_.isFunction(params.onEdit)) {
                  params.onEdit()
                }
              },
              onReset: function() {
                if (_.isFunction(params.onReset)) {
                  params.onReset()
                }
              },
              onBeforeUnload: function() {
                if (_fn.hasChanges()) {
                  return params.onUnload()
                }
              }
            },
            fn = {
              add: function(newScope) {

                scope = $(scope).add(newScope)

                var fields = $('input, textarea, select', newScope)

                $(fields).each(function(i, elm) {
                  var that = elm
                  if ($(elm).hasClass('is-watched')) {
                    return
                  }

                  $(elm).addClass('is-watched')
                  _fn.holdFieldState(elm)

                  $(elm).on('input propertychange change paste keyup blur', function() {
                    _fn.onChange(that)
                  })

                  /*
                   if($(elm)[0].onpropertychange){
                   console.log('elm on propertychange', elm);
                   $(elm).on('onpropertychange', function(){

                   });

                   }else{
                   if($(elm).is('input[type=radio], input[type=checkbox]')){
                   Object.defineProperty($(elm)[0], 'checked', {
                   set: function(val){
                   console.log('__SETTER: rd ch', val);
                   }
                   })

                   }else{
                   Object.defineProperty($(elm)[0], 'value', {
                   set: function(val){
                   console.log('__SETTER: tx', val);
                   }
                   })
                   }
                   }
                   */

                })
              },
              hasChanges: function() {
                return _fn.hasChanges()
              },
              resetState: function() {
                $('.' + params.class, $(scope)).removeClass(params.class)
              },
              triggerEdit: function(elm) {
                _fn.onChange(elm)
              }
            }

          var defaults = {
            scope: document,
            class: 'field-has-changed',
            onUnload: _fn.onUnloadChanged,
            onEdit: function() {
            },
            onReset: function() {
            },
            fnReset: function() {
            }
          }

          params = _.defaults(paramsRaw, defaults)

          if (typeof (params.onUnload) == 'function') {

            $(window).on('onbeforeunload beforeunload', _fn.onBeforeUnload)

            $(params.scope).parent().find('form').on('submit', function() {
              $(window).off('onbeforeunload beforeunload', _fn.onBeforeUnload)
            })

          }

          fn.add(params.scope)
          _fn.broadcast(params.scope)

          return fn

        },
        /**
         *
         * @param obj
         * @return {undefined|*}
         */
        objHash: function(obj) {
          var depth = arguments[1] || 0

          var objArr = []

          //console.debug('conl', obj, depth);

          if (depth > 100) return undefined // CONST_TOO_DEEP;

          switch (typeof (obj)) {
            case 'function':
              break
            case 'object':
              if (obj === null) return obj

              if (obj.___cloned) {
                return undefined // CONST_LOOP
              }
              obj.___cloned = 1

              if (Array.isArray(obj)) {
                //objArr = [];
              } else {
                //objArr = {};
              }

              for (var i in obj) {
                if (i !== '___cloned') { //} && objArr[i] === undefined){
                  objArr.push(this.objHash(obj[i], depth + 1))
                }
              }

              delete (obj.___cloned)
              break
            case 'undefined':
              objArr.push(-1)
              break
            default:
              objArr.push(obj.toString().hashCode())
              break
          }
          return objArr.join(',').hashCode()
        },

        /**
         * clones an object without loops and without functions
         * @param obj
         * @returns {*}
         */
        cloneObjBare: function(obj) {
          var depth = arguments[1] || 0

          var objClone

          //console.debug('conl', obj, depth);

          if (depth > 100) return undefined // CONST_TOO_DEEP;

          switch (typeof (obj)) {
            case 'function':
              break
            case 'object':
              if (obj === null) return obj

              if (obj.___cloned) {
                return undefined // CONST_LOOP
              }
              obj.___cloned = 1

              if (Array.isArray(obj)) {
                objClone = []
              } else {
                objClone = {}
              }

              for (var i in obj) {
                if (i !== '___cloned' && objClone[i] === undefined) {
                  objClone[i] = this.cloneObjBare(obj[i], depth + 1)
                }
              }

              delete (obj.___cloned)
              break
            case 'undefined':
            default:
              objClone = obj
              break
          }
          return objClone

        },

        /**
         *
         * @param obj
         * @param depth
         * @return {{}|undefined|*}
         */
        cloneObjNoLoop: function (obj, depth) {
          depth = depth || 0

          var objClone

          //console.debug('conl', obj, depth);

          if (depth > 100) return undefined // CONST_TOO_DEEP;

          switch (typeof (obj)) {
            case 'object':
              if (obj === null) return obj

              if (obj.___cloned) {
                return undefined // CONST_LOOP
              }
              obj.___cloned = 1

              if (Array.isArray(obj)) {
                objClone = []
              } else {
                objClone = {}
              }

              for (var i in obj) {
                if (i !== '___cloned' && objClone[i] === undefined) {
                  objClone[i] = this.cloneObjNoLoop(obj[i], depth + 1)
                }
              }

              delete (obj.___cloned)
              break
            case 'undefined':
            default:
              objClone = obj
              break
          }
          return objClone
        },

        /**
         * stringifies an object while being safe from loops ... sets a max depth of 5
         * @param obj
         * @param options
         * @returns {*}
         */
        obj2str: function (obj, options) {
          options = options || {}

          var depth = options.depth || 0,
            clones = options.clones || [],
            maxDepth = options.maxDepth || 100

          if (depth > maxDepth) return '##2deep##'

          var nextOptions = {}
          nextOptions.noFunctions = !!options.noFunctions
          nextOptions.depth = depth + 1
          nextOptions.clones = clones
          nextOptions.maxDepth = maxDepth

          if (typeof (obj) == 'undefined') {
            return 'undefined'
          }

          if (typeof (obj) == 'object') {
            if (obj === null) return 'null'

            if (obj.jquery) {
              if (obj.length === 1) {
                if (obj.html() !== undefined) {
                  return obj.html()
                } else {  // seems to be XML
                  var XMLS0 = new XMLSerializer()
                  return XMLS0.serializeToString(obj.get(0))
                }
              } else {
                return obj.wrap('<x/>').parent().get(0).html() // might return undefined on XML list (but unlikely)
              }
            }
            if (obj.outerHTML) { // seems to be a
              return obj.outerHTML
            } else if (obj.tagName && !obj.innerHTML) { // seems to be an XML node

              var XMLS1 = new XMLSerializer()
              return XMLS1.serializeToString(obj)
            }

            if (depth === 0) {
              obj = _.clone(obj, true)
            }

            if (obj.___cloned) {
              return '##loop##'
            }

            clones.push(obj)

            obj.___cloned = true

            var str = ('length' in obj) ? '[' : '{',
              i0 = 0
            for (var i in obj) {
              if (i === '___cloned') continue

              if (i0) str += ', '

              if ('length' in obj) {
                str += UTILS.obj2str(obj[i], nextOptions)
              } else {
                str += i + ':' + UTILS.obj2str(obj[i], nextOptions)
              }

              i0++
            }

            if (depth === 0) {
              // reset clone markers
              for (var i1 in clones) {
                delete (clones[i1].___cloned)
              }
            }

            return str + (str[0] === '{' ? '}' : ']')
          }

          if (typeof (obj) == 'function') {
            if (options.noFunctions) {
              return '[function ' + obj.toString().length + ']'
            }
          }

          var res = obj.toString()

          return isNaN(obj) ? '\'' + res.replace(/'/g, '\\\'') + '\'' : res
        },

        /**
         * String.hashCode()
         * simple hash function that converts a string into a 32bit integer
         * // via http://stackoverflow.com/a/7616484/2182191
         *
         * @returns {number}
         */
        hashCode: (function () {

          var hashCode = function () {
            var hash = 0, i, chr, len
            if (this.length === 0) return hash
            for (i = 0, len = this.length; i < len; i++) {
              chr = this.charCodeAt(i)
              hash = ((hash << 5) - hash) + chr
              hash |= 0 // Convert to 32bit integer
            }
            return hash
          }

          String.prototype.hashCode = hashCode

          return hashCode
        }()),

        /**
         * $(selector).value()
         *
         * fixes the glitch that .val() does not always return the real value of an input element, e.g. input[checkbox]
         * so it first checks if an element was selected and then returns the value
         *
         * @returns {*} the real current value of an element, needed especially for input[checkbox]
         */
        _jQValue: (function () {

          return $.fn.extend({
            value: function () {
              var val

              if ($(this).is('input[type=radio], input[type=checkbox]')) {
                val = $(this)[0].checked ? $(this).val() : null
              } else if ($(this).hasClass('richedit')) {
                val = $(this).html()
              } else if ($(this).is('select')) {
                val = $(this).val() ? $(this).val() : $('option', this).eq(0).attr('value')
              } else if ($(this).is('input')) {
                val = $(this).val() ? $(this).val() : null
              } else {
                val = $(this).val()
              }

              return val
            }
          })

        }()),

        /**
         * $(selector).shadowhtml(htmlStr)
         *
         * replaces in htmlStr <img src=> with <img __src=> on read-in and re-replaces it on export
         * this is used for quick dom rendering without having to worry about images being requested due
         * to the rendering
         */
        _jQShadowHtml: (function () {

          return $.fn.extend({
            shadowhtml: function (htmlStrObj) {
              if (typeof htmlStrObj !== 'undefined') {
                if (typeof htmlStrObj === 'string') {
                  var disarmedHtmlStr = htmlStrObj.replace(/(<img[^>]*)( src=)/igm, '$1 __src=')
                  $(this).html(disarmedHtmlStr)
                } else {
                  $(this).html(htmlStrObj)
                }

              } else {
                var armedHtmlStr = $(this).html().replace(/(<img[^>]*)( __src=)/igm, '$1 src=')
                return armedHtmlStr
              }

              return this
            }
          })

        }()),

        /**
         * $.isHovered(element) returns TRUE of mouse cursor is over this elements position based on its offset and height
         */
        _jQisHovered: (function () {

          var mousePos = { x: null, y: null }

          $(document).on('mousemove', _.debounce(function (ev) {
            mousePos.x = ev.pageX
            mousePos.y = ev.pageY
          }, 24))

          return $.fn.extend({
            isHovered: function () {
              var p = $(this).__position

              if (!p) {
                var offset = $(this).offset(),
                  topLeft = { y: offset.top, x: offset.left },
                  bottomRight = {
                    y: offset.top + $(this).outerHeight(),
                    x: offset.left + $(this).outerWidth()
                  }

                p = { topLeft: topLeft, bottomRight: bottomRight }
                $(this).__position = p
              }

              return mousePos.x > p.topLeft.x + 1 && mousePos.x < p.bottomRight.x - 1 &&
                mousePos.y > p.topLeft.y + 1 && mousePos.y < p.bottomRight.y - 1
            }
          })

        }()),

        _jQAttributes: (function () {
          /**
           * getter/setter for attributes via an object
           * @param attributesObj
           * @returns {*}
           */

          return $.fn.extend({
            attributes: function (attributesObj) {
              var _this = this
              if (!attributesObj) {
                attributesObj = {}

                _.forEach($(this)[0].attributes, function (attr) {
                  attributesObj[attr.name] = attr.value
                })

                return attributesObj
              }

              _.forEach(attributesObj, function (attrVal, attrName) {
                $(_this).attr(attrName, attrVal)
              })

              return _this
            }
          })

        }()),

        /**
         * @returns {fnOnce}
         */
        once: (function () {
          /**
           * runs a function only once
           * @param {function} runOnceFn
           * @alias fnOnce
           */
          window.once = function (runOnceFn) {
            var ranOnce = false
            return function () {
              if (ranOnce) return
              runOnceFn.apply(this, arguments)
              ranOnce = true
            }
          }

          return window.once
        }()),
        /**
         * @returns {fnAsync}
         */
        async: (function () {
          /**
           * async - runs a function via setTimeout
           * @param fn
           * @param delayedBy
           * @alias fnAsync
           */
          window.async = function (fn, delayedBy) {
            window.setTimeout(fn, delayedBy || 1)
          }

          return window.async
        }()),

        /**
         * waitFor
         * wait for 'condition' function to return true, then trigger success callback - after timeout trigger error callback
         * @param {function():T} conditionFn
         * @param {number} [timeoutMs=5000]
         * @param {function()} [succCb]
         * @param {function()} [errCb]
         * @param {number} [checkIntervalMs=250]
         * @returns {Promise<T>}
         */
        waitFor: function (conditionFn, timeoutMs, succCb, errCb, checkIntervalMs) {

          checkIntervalMs = checkIntervalMs || 250

          succCb = succCb || function () {}
          errCb = errCb || function () {}
          timeoutMs = timeoutMs !== undefined ? timeoutMs : 5000

          var _return = $.Deferred(),
            _promise = _return.promise()

          _promise.catch = function (fn) {
            _promise.fail(fn)
            return _promise
          }
          _promise.finally = function (fn) {
            _return.always(fn)
            return _promise
          }

          _return.done(succCb)
          _return.fail(errCb)

          var testFn = function () {
              var res = conditionFn()
              if (res) {
                clearAll()
                _return.resolve(res)
              }
            },
            testRunner = setInterval(testFn, checkIntervalMs),
            testTimeout = setTimeout(function () {
              clearAll()
              _return.reject()
            }, timeoutMs),
            clearAll = function () {
              clearInterval(testRunner)
              clearTimeout(testTimeout)
            }

          testFn()

          return _promise

        },
        /**
         *
         * @return {boolean}
         */
        hasTouch: function hasTouch () {
          return (('ontouchstart' in window) ||       // html5 browsers
            (window.navigator.maxTouchPoints > 0) ||   // future IE
            (navigator.hasOwnProperty('msMaxTouchPoints') && navigator.msMaxTouchPoints > 0))  // current IE10
        },

        /**
         * removes node from parent elm and destroys it
         * @param {HTMLElement} node
         */
        removeNode: function (node) {
          if (!node) return

          var pNode = node.parentNode
          if (pNode) {
            pNode.removeChild(node)
          }

          node = null
        },

        /**
         * @param {HTMLLinkElement} styleElm
         * @returns {HTMLElement}
         */
        appendStyleFile: function (styleElm) {
          var
            relCssFile = $(styleElm).attr('href'),
            styleId = 'css-' + relCssFile.hashCode(),
            styleNode = document.querySelector('head link[data-cstm-style-id=\'' + styleId + '\']')

          if (!styleNode) {
            styleNode = document.createElement('link')
            styleNode.setAttribute('rel', 'stylesheet')
            styleNode.setAttribute('href', relCssFile)
            styleNode.setAttribute('data-cstm-style-id', styleId)

            document.head.appendChild(styleNode)
          }

          return styleNode

        },

        /**
         * appends style with unique style-content once to head - returns new styleNode
         * @param {HTMLStyleElement} styleElm
         * @returns {HTMLStyleElement}
         */
        appendStyle: function (styleElm) {
          var
            cssStr = $(styleElm).attr('href') || $(styleElm).text(),
            styleId = 'css-' + cssStr.hashCode(),
            styleNode = document.querySelector('head style[data-cstm-style-id=\'' + styleId + '\']')

          if (!styleNode) {
            styleNode = document.createElement('style')
            styleNode.type = 'text/css'
            styleNode.setAttribute('data-cstm-style-id', styleId)
            document.head.appendChild(styleNode)
          }

          if (styleNode.styleSheet) {
            styleNode.styleSheet.cssText = cssStr
          } else {
            styleNode.appendChild(document.createTextNode(cssStr))
          }

          return styleNode
        },

        /**
         * appends array of scriptNodes synchronous to appendTo node - returns promise with all new script elements
         * @param scriptNodes
         * @param appendTo
         * @param promise
         * @param newScriptElmsArr
         */
        appendScripts: function (scriptNodes, appendTo, promise, newScriptElmsArr) {

          var scriptsLoading = promise || $.Deferred(),
            newScriptNodes = newScriptElmsArr || []

          if (scriptNodes.tagName === 'SCRIPT') {
            scriptNodes = [scriptNodes]
          }

          if (!scriptNodes || scriptNodes.length === 0) {

            scriptsLoading.resolve(newScriptNodes)

          } else { // scriptNodes && scriptNodes.length > 0

            UTILS.appendScript(scriptNodes.shift(), appendTo)
              .then(
                function done(newScriptElm){
                  newScriptNodes.push(newScriptElm)
                  UTILS.appendScripts(scriptNodes, appendTo, scriptsLoading, newScriptNodes)
                },
                function fail(err){
                  // remove all other new script elements
                  newScriptNodes.forEach(function (newScriptNode) {
                    UTILS.removeNode(newScriptNode)
                  })

                  scriptsLoading.reject(err)
                }
              )

          }

          return scriptsLoading.promise()

        },

        /**
         * tests a given JS script source for syntax errors without executing the JS script
         * @param {string} rawScript
         * @returns {Error}     if given JS script has bad syntax an error is returned
         */
        testScriptForSyntaxError: function (rawScript) {

          try {
            eval(['undefined', 'Function', 'Xy1', '();\n', rawScript].join(''))
          } catch (err) {
            if (!err.message.match(/undefinedFunctionXy1/i)) { // expecting something along "'undefinedFunctionXy1' is undefined" -> valid syntax

              var
                line = err.lineNumber ? err.lineNumber - 1 : null,
                col = err.columnNumber,
                colStart = (!col || col < 50) ? 0 : col - 50,
                colEnd = colStart + 100

              if (line && col) {
                err.message +=
                  ' (line ' + line + ', col ' + col + ', \'... '
                  + rawScript.split('\n')
                    .splice(line - 1, 1)[0]
                    .slice(colStart, colEnd)
                  + ' ...\')'
              }

              return err
            }
          }

        },

        /**
         * appends single script node to node, returns new script node in promise on load
         * @param {HTMLScriptElement} scriptNode
         * @param {HTMLElement} appendTo
         * @returns {Promise<any>}
         */
        appendScript: function (scriptNode, appendTo) {

          var loadingScript = $.Deferred(),
            newScriptNode = document.createElement('script'),
            isInlineScript = !!scriptNode.innerHTML,
            isLocalScript = scriptNode.src && (scriptNode.src.match(/:\/\/([^/]+)\//) || [])[1] === window.location.host,
            refScriptSrc,
            attr,
            tmpPromiseRefName = 'tmpPromiseRef' + Date.now() + '_' + (Math.random() * Date.now()).toFixed(),
            promiseWrapper,
            SE

          newScriptNode.async = false

          for (var i in scriptNode.attributes) {
            attr = scriptNode.attributes[i]

            if (attr.nodeName) {

              if (attr.nodeName.toLowerCase() === 'src') {
                refScriptSrc = attr.nodeValue

              } else {
                newScriptNode.setAttribute(attr.nodeName, attr.nodeValue)

              }
            }
          }

          SE = window.SE = window.SE || {}
          SE.TMP = SE.TMP || {}
          promiseWrapper = SE.TMP[tmpPromiseRefName] = {
            reject: function (err) {

              switch (typeof err) {
                case 'object':
                  break
                default:
                case 'string':
                case 'undefined':
                  err = new Error(err)
                  break
              }

              err.isInline = isInlineScript
              err.scriptRef = _.trunc(scriptNode.outerHTML, { length: 250, omission: '...\n</script>' })

              loadingScript.reject(err)
            },
            resolve: function () {
              loadingScript.resolve(newScriptNode)
            }
          }

          function wrapScriptWithLoaderPromise (rawScript) {

            var promiseVarName = 'window.SE.TMP.' + tmpPromiseRefName

            return 'try{ ' +
              '\n' + ' /* inline script START */ ' +
              '\n' +
              '\n' + ' ' + rawScript + ' ' +
              '\n' +
              '\n' + ' /* inline script END */; ' +
              '\n' + '   ' + promiseVarName + '.resolve(); ' +
              '\n' + '}catch(err){ ' +
              '\n' + '   ' + promiseVarName + '.reject(err); console.warn(err); ' +
              '\n' + '}' +
              '\n'

          }

          if (isInlineScript) {

            var rawScript = scriptNode.innerHTML,
              err = UTILS.testScriptForSyntaxError(rawScript)

            if (err) {
              promiseWrapper.reject(err)

            } else {
              newScriptNode.innerHTML = wrapScriptWithLoaderPromise(rawScript)

            }

          } else {

            var scriptLoadTimeout
            newScriptNode.addEventListener('load', function () {
              clearTimeout(scriptLoadTimeout)
              setTimeout(function () {
                promiseWrapper.resolve()
              }, 125)
            })
            newScriptNode.addEventListener('error', function (err) {
              clearTimeout(scriptLoadTimeout)
              promiseWrapper.reject(err)
            })

            scriptLoadTimeout = setTimeout(function () {
              promiseWrapper.reject(new Error('timeout loading external script'))
            }, ENV.async_timeout_seconds * 1000)

            if (isLocalScript) {

              $.ajax(refScriptSrc, {
                dataType: 'text',
                success: function (rawScript, textStatus, jqXHR) {

                  if (typeof rawScript === 'undefined' || rawScript === '') {
                    return promiseWrapper.reject(new Error('error loading local script file ', refScriptSrc))
                  }

                  var err = UTILS.testScriptForSyntaxError(rawScript)

                  if (err) {
                    return promiseWrapper.reject(err)
                  }

                  // newScriptNode.innerHTML = wrapScriptWithLoaderPromise(rawScript);
                  // appendTo.appendChild(newScriptNode);
                  //
                  // promiseWrapper.resolve();

                  newScriptNode.src = refScriptSrc
                  appendTo.appendChild(newScriptNode)

                },
                error: function (jqXHR, textStatus, errorThrown) {
                  promiseWrapper.reject(errorThrown)
                }
              })

            } else {
              newScriptNode.src = refScriptSrc
            }
          }

          try {
            if (newScriptNode.src || newScriptNode.innerHTML) {
              appendTo.appendChild(newScriptNode)
            }

          } catch (err) {
            loadingScript.reject(err)
          }

          return loadingScript.promise()

        },
        /**
         * IE11 compatible vanilla js custom event dispatch
         * @param {HTMLElement} node
         * @param {string} eventType
         * @param {object} [options]
         * @param {boolean} [options.bubbles=false]
         * @param {boolean} [options.cancelable=false]
         * @param {boolean} [options.composed=false]
         * @param {any} [options.detail]
         *
         * @see https://stackoverflow.com/a/49071358/2182191
         */
        dispatchCustomEvent: function (node, eventType, options) {
          var event
          options = options || { bubbles: false, cancelable: false, composed: false, detail: undefined }

          if (typeof (Event) === 'function') {
            event = new Event(eventType, options)

          } else {
            event = document.createEvent('Event')
            event.initEvent(eventType, options.bubbles, options.cancelable)
          }

          if (options.detail) {
            event.detail = options.detail
          } else if (options.details) {
            console.warn(LOG_LABEL, 'dispatchCustomEvent', 'do not use `details` but `detail`!', node, eventType, options)
          }

          node.dispatchEvent(event)
        }
      }

    return UTILS

  });
