define('app/components/browsercheck',[
  'app/helper/browsercheck',
  'toasties'
], function (
  browsercheck,
  Toasties
) {
  return function () {
    browsercheck.check({
      alertFn: Toasties.warn
    })
  }

})
;
