!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('toasties',[
      'toastr'
    ], factory)
  }
}(
  /**
   * @param {Toastr} Toastr
   * @returns SeToasties
   */
  function (
  Toastr
) {
  window.toastr = Toastr

  /**
   * Toasties
   *
   * wrapper class for Toastr     *
   * offers functions to show prepared and styled toasties with messages to the user
   */

  var LOG_LABEL = 'TOASTIES'

  var Utils = {
    simpleMerge: function () {
      var target = arguments[0]
      for (var i = 1; i < arguments.length; i++) {
        for (var i2 in arguments[i]) {
          target[i2] = arguments[i][i2]
        }
      }
      return target
    }
  }

  var _v = {
      isUnloading: false,
      options: {
        btnDefault: {
          label: 'Close',
          callback: null,
          class: 'btn-primary'
        },
        bigTopRight: {
          closeButton: true,
          tapToDismiss: false,
          preventDuplicates: true,
          timeOut: 0,
          progressBar: false,
          extendedTimeOut: 0,
          containerId: 'toastr-container-top-right',
          positionClass: 'toast-top-right'
        },
        tinyTopCenter: {
          closeButton: false,
          tapToDismiss: true,
          debug: false,
          progressBar: true,
          preventDuplicates: false,
          positionClass: 'toast-top-center toast-tiny',
          wide: false,
          onclick: null,
          showDuration: 400,
          hideDuration: 1000,
          timeOut: 3000,
          containerId: 'toastr-container-top-center',
          extendedTimeOut: 1000
        }
      },
      showingToasts: {}
    },
    _fn = {
      init: function () {
        window.addEventListener('beforeunload', function () {
          _v.isUnloading = true
          Toastr.clear()
        })
      },
      clear: function(toast){
        if (toast) {
          Toastr.clear(toast, { force: true })
          if(toast.uid){
            delete(_v.showingToasts[toast.uid])
          }
        }
        else {
          Toastr.clear()
          _v.showingToasts = {}
        }

      },
      /**
       * @param {string} type
       * @param {string} [message='']
       * @param {string} title
       * @param {string} optionType - bigTopRight, tinyTopCenter
       * @param {object} [btnOptions]
       * @param { ({toastyUid}|ToastrOptions) } [moreOptions] extended toastr options; if toastyUid is set this toast is only shown once
       * @returns {Toasty}
       */
      showToast: function (type, message, title, optionType, btnOptions, moreOptions) {
        moreOptions = moreOptions || {
          toastyUid: null
        }
        if (_v.isUnloading) return

        var btn

        // -- check and set default values

        if (!Toastr[type]) {
          console.error(LOG_LABEL, 'unsupported toasty type', type, arguments)
          return
        }

        if (!_v.options[optionType]) {
          console.error(LOG_LABEL, 'unsupported toasty options', optionType, arguments)
          return
        }

        var options = Utils.simpleMerge({}, _v.options[optionType], moreOptions)

        title = title || null

        message = message || ''

        if (Array.isArray(message)) {
          message = message.join('\n')
        }

        message = message.replace('\n', '<br/>')

        // -- prepare button

        if (btnOptions) {
          var defaultBtnOptions = Utils.simpleMerge(_v.options.btnDefault, { class: 'btn-' + type })
          btn = {}

          for (var attr in defaultBtnOptions) {
            var val = defaultBtnOptions[attr]
            btn[attr] = btnOptions[attr] || val
          }

          btn.elm = '<div style="padding-top:.5em;" class="text-right">' +
            '<button type="button" class="btn ' + btn.class + ' clear-toasty"><b>' + btn.label + '</b></button>' +
            '</div>'

          // with a button -> disable tapToDismiss
          options.tapToDismiss = false

        }
        // make sure onclick is set as it auto-triggers "hide" after onclick() is called
        options.onclick = options.onclick || function () {}

        if (options.wide) {
          options.positionClass += ' toast-wide'
        }

        var
          isShown = true,
          optOnHidden = options.onHidden || function () {},
          onHidden = function () {
            isShown = false
            optOnHidden()
          }
        options.onHidden = onHidden

        // -- set options and show toasty

        if(options.toastyUid){
          var toast0 = _v.showingToasts[options.toastyUid]
          if(toast0 && toast0.isShown()){
            return toast0
          }
        }

        var toastMessage = message && message.length ? '<p>' + message + '</p>' : '',
          toastBtn = btn ? btn.elm : '',
          toast = Toastr[type](toastMessage + toastBtn, title, options),
          btnCallback = btn && btn.callback

        if (toast) {
          // -- set button callback
          if (btn && toast.find('.clear-toasty').length) {
            toast.delegate('.clear-toasty', 'click', function () {
              if (typeof btn.callback === 'function') {
                btn.callback.apply(this)
              }

              toast.clear()
            })
          }

          var isClearing = false
          toast.clear = function () {
            if (isClearing) return
            isClearing = true

            // click triggers hide() which in return triggers option.onHidden
            toast.click()
            // is not triggering option.onHidden
            _fn.clear(toast)
          }

          toast.isShown = function () {
            return isShown
          }
        }

        if(options.toastyUid){
          toast.uid = options.toastyUid
          _v.showingToasts[options.toastyUid] = toast
        }

        return toast
      }

    },
    fn = {
      warn: function (title, message, buttonLabel, buttonFn, options) {

        var btn = {
          label: buttonLabel,
          callback: buttonFn,
          class: 'btn-warning'
        }

        return _fn.showToast('warning', message, title, 'bigTopRight', btn, options)

      },
      warning: function (title, message, buttonLabel, buttonFn, options) {
        // deprecated -- use "warn" instead // TODO: replace all "Toasties.warning" with "Toasties.warn"
        return fn.warn.apply(this, arguments)
      },
      error: function (title, message, buttonLabel, buttonFn, options) {

        var btn = {
          label: buttonLabel,
          callback: buttonFn,
          class: 'btn-danger'
        }

        return _fn.showToast('error', message, title, 'bigTopRight', btn, options)

      },
      success: function (title, message, buttonLabel, buttonFn, options) {

        var btn = {
          label: buttonLabel,
          callback: buttonFn,
          class: 'btn-success'
        }

        return _fn.showToast('info', message, title, 'bigTopRight', btn, options)

      },

      info: function (title, message, buttonLabel, buttonFn, options) {

        var btn = {
          label: buttonLabel,
          callback: buttonFn,
          class: 'btn-info'
        }

        return _fn.showToast('info', message, title, 'bigTopRight', btn, options)

      },
      tiny: function (title, message, quick, options) {

        var quickTimeout = 750,
          moreOptions = Utils.simpleMerge({
            progressBar: !quick, // = hide by default if quick
            type: 'info',
            timeout: quick ? quickTimeout : null,
            wide: false
          }, options || {})

        return _fn.showToast(moreOptions.type, message, title, 'tinyTopCenter', null, moreOptions)

      },
      clear: function (toast) {
        _fn.clear(toast)
      }

    }

  return fn

})

/**
 * @typedef SeToasties
 * @property {ToastyFunction} warn
 * @property {ToastyFunction} warning
 * @property {ToastyFunction} error
 * @property {ToastyFunction} success
 * @property {ToastyFunction} info
 * @property {function(string, message, boolean, [object] )} tiny
 * @property {function(Toast)} clear
 */

/**
 * @typedef ToastyFunction
 * @kind function
 * @param {string} title
 * @param {string} [message]
 * @param {string} [btnLabel]
 * @param {function} [btnFunction]
 * @param {object} [options]
 */

/**
 * @typedef ToastyOptions
 * @kind object
 * @property toastyUid
 */;
