!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('app/helper/cookies',[], factory)
  }
}(
  /**
   * @typedef seCookies
   * @property {(function(): string|undefined)} getUISID returns UISID cookie value
   * @property {(function(name: string, {forced: boolean}=): string|undefined)} get returns the value for name cookie
   */
  /**
   * @return {seCookies}
   * @constructor
   */
  function Cookies () {
    var LOG_LABEL = 'H.CKS'

    var
      _v = {
        state: {
          /**
           * @type {Object.<name: string, value: string>}
           */
          cookies: {}
        }
      }

    _parse()

    return {
      get: getCookieValue,
      getUISID: function () { return getCookieValue('UISID') }
      // TODO: set(name, value, params)
      // TODO: delete(name)
    }

    /**
     * parse in current document cookies and return cookie object;
     * if params.forced _parse is re-parsing document cookies; otherwise it is serving a cached pre-parsed state
     * @param {Object} [params]
     * @param {boolean} params.forced
     * @return {Object.<name: string, value: string>} object of name:value cookie values
     */
    function _parse (params) {
      params = params || {
        forced: false
      }

      if (typeof document === 'undefined')
        return {}

      if (Object.keys(_v.state.cookies).length && !params.forced) {
        return _v.state.cookies
      }

      var cookies = document.cookie.split(';'),
        cookiesObj = cookies.reduce(function (r, c) {
          var _c = c.trim().split('='),
            name = _c[0],
            value = _c[1]

          r[name] = value
          return r
        }, {})

      _v.state.cookies = cookiesObj

      return cookiesObj
    }

    /**
     * get value for cookie with name or undefined
     * @param {string} name
     * @param {Object} [params] param object forwarded to _parse() method
     * @param {boolean} params.forced force a re-parsing of the current cookie state
     * @return {string|undefined} cookie value
     */
    function getCookieValue (name, params) {
      var cookies = _parse(params)
      return cookies[name]
    }

  }
);
