(function (f) { if (typeof module !== 'undefined') { module.exports = f() } else { define('app/config/constants',f) }}(function () {

  var
    /**
     * @typedef {{STATUS_OBJ_ACTIVE: string, STATUS: {LOADING: string, READY: string, CHANGED: string, SAVING: string, RUNNING: string, NOT_LOADED: string, RESET: string, DELETED: string, LOADED: string, PRELOADED: string, PAUSED: string, COMPLETE: string, FAILED: string, QUEUED: string, REMOVING: string, STOPPED: string, SAVED: string, TEMPORARY: string, DESTROYED: string, TIMEOUT: string}, EVENTS: {CONNECTION: {READY: string, FAILED: string, LOST: string}}, PREFIX_TEMPORARY: string, ERRORS: {TOO_MANY_ELEMENTS: string, TOO_MANY_PAGES: string}, STATUS_OBJ_DELETED: string, VALUES: {DEFAULT_COLUMN_LAYOUT: string, DEFAULT_CONDITION: string, MSG_MISSING_VALUE_ERROR: string, DEFAULT_MATRIX_LAYOUT: string}, CLASSES: {INVALID_FIELD_CLASS: string}, SETTINGS: {NAVBAR_MINIFIED: string, FAST_SIMULATION_CONFIG: string}, EXPERIMENT: {TYPES: {PAIRED: string, MIXEDGENERIC: string, ALTSPEC: string}, FILE_FORMAT_TYPES: {SURVEYENGINE: string, NGENE: string}, XML_ELEMENTS: {DEPENDENT_VARIABLE: string, DESIGN: string, ATTRIBUTE: string, ALTERNATIVE: string, PROPERTY: string, ATTRIBUTE_ASSIGNMENT: string, LAYOUT: string, LEVEL: string, EXPERIMENT: string}, CHOICE_TYPES: {SINGLE: string, BESTWORST: string, CUSTOM: string, FULL: string}}, STORAGE_TYPE: {LOCAL: string, SESSION: string}}} SeConstants
     */
    /**
     *
     * @type SeConstants
     */
    CONSTANTS = {

      STATUS: {

        // ! the order (set by the values in alphabetic order) is important for a flawless status livecycle
        TEMPORARY: '10-temporary',

        NOT_LOADED: '19-notloaded',
        PRELOADED: '20-preloaded', // e.g. if data is already received from the backend but not parsed
        LOADING: '21-loading',   // parsing the data is loading it as well as retrieving it from an api
        LOADED: '22-loaded',

        READY: '22-ready',     // e.g. when sub-data is also loaded to a state that the object can be properly used (normally "loaded" is enough

        RESET: '23-reset',
        QUEUED: '24-queued',
        RUNNING: '25-running',
        PAUSED: '26-paused',
        STOPPED: '27-stopped',
        COMPLETE: '28-complete',

        CHANGED: '30-changed',
        SAVING: '31-saving',
        SAVED: '32-saved',

        DELETED: '50-deleted',
        REMOVING: '52-removing',
        DESTROYED: '54-destroyed',
        TIMEOUT: '56-timeout',

        FAILED: '99-failed'
      },

      EVENTS: {
        CONNECTION: {
          READY: 'con-ready',
          LOST: 'con-lost',
          FAILED: 'con-failed'
        }
      },

      STATUS_OBJ_DELETED: 'deleted',
      STATUS_OBJ_ACTIVE: 'active',

      PREFIX_TEMPORARY: 'TMP',

      VALUES: {
        MSG_MISSING_VALUE_ERROR: 'This item must be answered',

        DEFAULT_CONDITION: '1 == 1',
        DEFAULT_COLUMN_LAYOUT: '1 column',

        DEFAULT_MATRIX_LAYOUT: 'default'

      },

      EXPERIMENT: {
        XML_ELEMENTS: {
          EXPERIMENT: 'experiment',
          DEPENDENT_VARIABLE: 'dependent_variable',
          ATTRIBUTE: 'attribute',
          ALTERNATIVE: 'alternative',
          ATTRIBUTE_ASSIGNMENT: 'attribute_assignment',
          LEVEL: 'level',
          DESIGN: 'design',
          LAYOUT: 'layout',
          PROPERTY: 'property'
        },
        TYPES: {
          PAIRED: 'paired',
          ALTSPEC: 'altspec',
          MIXEDGENERIC: 'mixed generic'
        },
        CHOICE_TYPES: {
          SINGLE: 'single',
          BESTWORST: 'bestworst',
          FULL: 'full',
          CUSTOM: 'custom'
        },

        FILE_FORMAT_TYPES: {
          SURVEYENGINE: 'experiment',
          NGENE: 'ngene'
        }
      },

      ERRORS: {
        TOO_MANY_PAGES: 'TOO_MANY_PAGES',
        TOO_MANY_ELEMENTS: 'TOO_MANY_ELEMENTS'
      },

      CLASSES: {
        INVALID_FIELD_CLASS: 'invalid-value'
      },

      SETTINGS: {
        FAST_SIMULATION_CONFIG: 'SE_FAST_SIMULATION_CONFIG',
        NAVBAR_MINIFIED: 'SE_NAVBAR_MINIFIED'
      },

      STORAGE_TYPE: {
        SESSION: 'STORAGE_SESSION',
        LOCAL: 'STORAGE_LOCAL'
      }

    }

  return CONSTANTS

}))
;
