define('app/app.status',[],function () {

  return {
    _stati: {
      0: 'loading',
      1: 'loaded',
      2: 'initialized',
      3: 'ui-loaded'
    },
    _current: 0,
    is: function () {
      return this._current
    },
    set: function (statusLevel) {
      if (this._stati[statusLevel]) {
        this._current = statusLevel

        //log.log('set status level', statusLevel);
      }
    }
  }

});
