define('app/app.view',[
  //'lodash',
  'app/components/browsercheck',
  'app/helper/connectionstatus.se',
  'app/app.utils',

  'app/helper/logging.se',
  'app/helper/events.se',
  'app/helper/settings',
  'app/config/constants',
  'toasties',
  'app.env'
],
  /**
   *
   * @param browsercheck
   * @param {ConnectionStatusModelInstance} connectionStatus
   * @param Utils
   * @param seLogging
   * @param {SeEventBus} seEvents
   * @param Settings
   * @param CONST
   * @param {SeToasties} Toasties
   * @param {AppEnv} ENV
   * @returns {{init: function(): void, render_once: *, load: function(*, *): void, render: function(*): void}}
   */
  function (
  browsercheck,
  connectionStatus,
  Utils,
  seLogging,
  seEvents,
  Settings,
  CONST,
  Toasties,
    ENV
) {

  var LOG_LABEL = 'APP.VIEW'

  //console.log("########### ---------------------- APP VIEW ------------------------");
  var app = {}

  //app.utils = Utils;

  var _v = {
      backThen: Date.now()
    },
    _fn = {
      views: [ // controller names from ./controllers/*_template.js
        'payment_template',
        'upgrade_template',
        'account_template',
        'manage_surveys_template',
        'manage_assets_template',
        'manage_secure_uploads_template',
        'manage_versions_template',
        'fast_simulation_template',
        'publish_template',
        'dictionary_template',
        'report_template',
        'report.report_template',
        'data_view_template',
        'frequencies_template',
        'model_template',
        'crosstabs_template',
        'invitation_summary_template',
        'invitation_detail_template',
        'survey_layout_template',
        'edit_page_template',
        'edit_experiment_template',
        'experiment_layout_template',
        'experiment_design_template',
        'experiment_custom_template',
        'live_summary_template',
        'play_template',
        'experiment_demo_template',
        'device_demo_template'
      ],
      new_screens: [
        'ngene_script_template',
      ],
      _view: null,
      _view_isInit: false,

      utils: {
        triggerPrintmode: function () {
          if (location.search.match(/\W+out=print(\W+.*)?$/)) {
            $('html').addClass('print')
          }
        }
      },
      handleApiLimits: function(){
        seEvents.on('error:api', function(data){
          var
            /** @type ApiErrorEventData */
            _data = data,
            op = _data.op,
            err = _data.data,
            status = _data.status,
            xhr = _data.xhr

          switch(status){
            case 403:
            case 412:
              // slightly delayed - allows a specific 403 toasty to be shown .. only 1 of type 'error-api-403' will be shown
              setTimeout(function(){
                Toasties.warn('Limit reached', 'The requested action is not permitted because ' +
                  'subscription limits have been reached or the subscription has expired.', null, null, {toastyUid: 'error-api-403'});
              }, 100)

              break;
          }

        })
      },
      /**
       * disables all contenteditable, input, textarea select fields and elements with attribute `data-editing` != false
       * setting attribute `data-editing=false` on an input field excludes it from being disabled
       * a dom observer makes sure also later added items will be automatically disabled
       */
      switchInputElementsToReadonly: function(){
        var $ = window.$ || window.jQuery

        disableForms()
        registerDomObserver(disableForms)

        function disableForms() {
          $('.richedit, select, input:not([type=hidden]), textarea, [data-editing], [contenteditable]').not('[data-editing=false]')
            .attr('disabled', 'disabled')
            .attr('title', 'Disabled due to subscription limits')
            .each(function(i, elm){
              var $elm = $(elm)

              // find possible label
              var $label = $elm.closest('label')
              if(elm.id){
                $label = $($label.toArray().concat($('label[for=' + elm.id + ']').toArray()))
              }
              $label
                .attr('disabled', 'disabled')
                .attr('title', 'Disabled due to subscription limits')

              if($elm.is('[contenteditable]')){
                $elm.attr('contenteditable', false)
              }
            })

          $('a[disabled], .btn[disabled], button[disabled]').each(function(i, elm){
            if(elm._seReadonly) return
            elm._seReadonly = true
            $(elm).on('click', function(ev){
              ev.preventDefault()
              return false
            })
          })
        }

        function registerDomObserver(cb) {
          var domObs = new MutationObserver(cb)
          domObs.observe(document.body, {
            childList: true,
            attributes: false,
            subtree: true
          })
        }
      }
    },
    fn = {
      load: function (env, status) {
        app.env = env
        app.status = status

        var self = this,
          viewName

        if (Settings.available) {
          var setting = Settings.session(CONST.SETTINGS.NAVBAR_MINIFIED)

          if (setting.get()) {
            $('body').addClass('mini-navbar')
          }

          $('.navbar-minimalize').on('click', function (ev) {
            setTimeout(function () {
              if ($('body').hasClass('mini-navbar')) {
                // did expand
                setting.set(true)
              } else {
                // did minimalize
                setting.set(false)
              }
            }, 2)

          })
        }

        // get view name from environment variables set in ui_frame.html
        if (app.env.screen) {
          viewName = app.env.screen.replace('_screen', '_template')

          if (_fn.views.indexOf(viewName) > -1) {

            // load controller file
            require(['app/controllers/' + viewName + (typeof window === 'object' ? '.v.' + window.SE.COMMIT_HASH : '')], function (_view) {

              // load controller from as optimized module
              if (!_view) {
                require(['seApp/controllers/' + viewName], function (_view) {
                  _fn._view = _view
                  _fn._view_isInit = false

                  console.log('view ? ', self._view)

                  self.init()
                })

              } else {

                _fn._view = _view
                _fn._view_isInit = false

                console.log('view ? ', self._view)

                self.init()
              }

            })

          } else {
            var screenName = app.env.screen

            function loadModule (fileName) {
              var
                scriptNode = document.createElement('script'),
                _res = $.Deferred()

              // TODO: make IE11 compatible
              scriptNode.setAttribute('type', 'module')
              scriptNode.src = fileName
              scriptNode.onload = _res.resolve
              scriptNode.onerror = _res.reject
              document.body.appendChild(scriptNode)

              return _res.promise()
            }

            var fileName = '/lib/reskin/js/dist/seApp/screens/' + screenName + (typeof window === 'object' ? '.v.' + window.SE.COMMIT_HASH : '') + '.js'

            loadModule(fileName)
              .then(function () {
                _fn._view = {
                  init: function () {},
                  render: function (onRenderedCb) {
                    onRenderedCb()
                  }
                }
                _fn._view_isInit = false
                self.init()
              }, function (err){
                console.error(LOG_LABEL, 'failed to load screen controller', err);
              })

          }
        } else {
          console.warn(LOG_LABEL, 'no screen requested!', app.env.screen, app.env)
        }

        console.debug('app view load', viewName)
      },
      init: function () {
        var tmpFn

        if (app.status.is() < 3) return
        //log.log('try init view controller', app.status.is(), this._view);

        fn.render()

        if (_fn._view && !_fn._view_isInit) {
          _fn._view_isInit = true
          //log.log('init view controller', this._view);

          if (_fn._view.init) {
            _fn._view.init()
          }

          // important info for loading indicators and also the testing
          var onRenderedCb = once(function () {
            $('body')
              .attr('data-view-rendered', (Date.now() - _v.backThen) / 1000)
              .trigger('view.rendered') // used e.g. to resize once more an auto-height element


            // echo to check session validity
            connectionStatus.ping({ forced: true, interval: false })

            if(ENV.subscription_expired){
              _fn.switchInputElementsToReadonly()
            }

            $(window).on('focus', function(){
              // echo to check session validity
              connectionStatus.ping({ forced: true, interval: false })
            })
          })
          if (_fn._view.render) {
            // automatically call it rendered after 3.9s (5s is the waitFor timeout on the tests
            // TODO: remove the timeout and make sure all render() call the callback when done
            setTimeout(onRenderedCb, 3900)

            _fn._view.render(onRenderedCb)

          } else {
            onRenderedCb()
          }

          if (_fn._view._html) {
            fn.render(_fn._view._html)
          }

          if (!_fn._view.render_global)
            _fn._view.render_global = fn.render
        }
        console.debug('app view init')
      },
      render_once: window.once(function () {

        $(document).on('shown.bs.modal', '.modal', function () {
          $('input:not([type=hidden]), select, textarea', this).first().focus()
        })

        var screenErrors = app.env.screen_errors()
        var screenWarnings = app.env.screen_warnings()
        var screenSuccess = typeof app.env.screen_success === 'function' ? app.env.screen_success() : []

        if (screenWarnings.length) {
          var warningTitle = screenWarnings.shift(),
            warningMessage = screenWarnings.join('<br/>')
          seLogging.warn(warningTitle, warningMessage)
        }
        if (screenErrors.length) {
          var errorTitle = screenErrors.shift(),
            errorMessage = screenErrors.join('<br/>')
          seLogging.error_plain(errorTitle, errorMessage)
        }
        if (screenSuccess.length) {
          var successTitle = screenSuccess.shift(),
            successMessage = screenSuccess.join('<br/>')
          seLogging.success(successTitle, successMessage)
        }

        browsercheck()

      }),
      render: function (scope) {
        scope = scope || document

        //app.utils.watchForms(scope);

        if (scope === document) {
          fn.render_once()

          _fn.utils.triggerPrintmode()
        }

        var surveyTitle = $('[data-task="survey_title"]').html(),
          $pageHeading = $('.page-heading h2')
        if ($pageHeading.length && !$pageHeading.find('.survey_title').length && surveyTitle) {
          $pageHeading.append('<span class="survey_title">' + surveyTitle + '</span>')
        }

        console.debug('app view render', scope)

        // removing ".animated" on animation end - animation is played anyway only once
        // this removes e.g. the positioning of the element due to the "animated" which
        // creates troubles when a contained element needs to be "position:fixed"
        $(document).on('animationend', '.animated', function (ev) {
          var elm = ev.target
          $(elm).removeClass('animated')

          console.log(LOG_LABEL, 'ANIMATION EEEEEEEEEEEEEEEEEEND', ev)
        })

      }
    }

  _fn.handleApiLimits()

  return fn
})
;
