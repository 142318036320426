!function (factory) {
  if (typeof module !== 'undefined') { module.exports = factory } else {
    define('app.env', [
      'app/config/defaults'
    ], factory)
  }
}(function (defaults) {

  /**
   * @typedef AppEnv
   * @property {number} USER_IS_ACTIVE_DELAY_MS
   * @property {string} screen_name
   * @property {string} screen
   * @property {string} survey_name
   * @property {string} API_BASE_URL
   * @property {number} auto_save_seconds
   * @property {1|0} live_ngene
   * @property {number} async_timeout_seconds
   *
   * @property {boolean} subscription_expired
   *
   * @property {number} MAX_UPLOAD_USER max allowed upload size for user sessions in MB
   * @property {number} MAX_UPLOAD_RESPONDENT max allowed upload size for play sessions in MB
   *
   */
    // TODO: update to latest state

  var
    /**
     * @type AppEnv
     */
    ENV = getEnv()

  defaults.load(ENV, 'ENV')

  return ENV

  /**
   *
   * @return {{}|*}
   */
  function getEnv () {
    if (typeof window !== 'undefined' && window.SE) {
      return window.SE && window.SE.APPENV
    } else if (typeof SE !== 'undefined' && SE) {
      return SE.APPENV
    }
    console.warn('No SE.APPENV found')
    return {}
  }

});
